import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import KeywordOverview from './KeywordOverview'
import { InnovationCreate } from '..'

class KeywordConfirmation extends Component {
  static contextType = HttpClientContext

  state = {
    idea: null,
    isInputMode: false,
    ideaWasDeleted: false,
    ideaWasSubmitted: false,
    products: [],
  }

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props
    const httpClient = this.context
    const idea = await httpClient.get(`/innovation_idea/${id}`)
    let products = await httpClient.get('/product/')

    // Add default value to reset to
    products.unshift({ id: '', title: 'Produkt' })

    this.setState({ idea, products })
  }

  toggleView = () => {
    this.setState(prevState => {
      return { isInputMode: !prevState.isInputMode }
    })

    // reset scrollposition
    window.scrollTo({ top: 0 })
  }

  convert = async () => {
    const httpClient = this.context
    const idea = this.state.idea
    await httpClient.get(
      `/innovation_idea/${idea.id}/convert_to_innovation_idea`
    )

    this.componentDidMount()

    this.setState({ ideaWasSubmitted: true })
  }

  handleKeywordDeletion = async () => {
    const httpClient = this.context
    await httpClient.delete(`/innovation_idea/${this.state.idea.id}`)
    this.setState(prevState => {
      return { ideaWasDeleted: true }
    })
  }

  handleSubmit = async () => {
    let idea = this.state.idea
    const formData = new FormData()
    idea.confirmationPending = false
    formData.append('json', JSON.stringify(idea))
    formData.append('file', idea.image)

    const httpClient = this.context
    const url = `/innovation_idea/${idea.id}/edit`
    await httpClient.post(url, formData)

    this.setState(prevState => {
      return { ideaWasSubmitted: true }
    })
  }

  handleInputChange = event => {
    const {
      target: { name, value },
    } = event

    const { idea } = this.state
    idea[name] = value
    this.setState({ idea })
  }

  handleImageChange = event => {
    let image = event.target.files[0]
    if (!image) return

    this.setState(prevState => {
      const idea = prevState['idea']
      return {
        idea: {
          ...idea,
          image,
        },
      }
    })
    this.previewImage(image)
  }

  previewImage = image => {
    const reader = new FileReader()

    reader.onload = event =>
      this.setState(prevState => {
        const idea = prevState.idea

        return {
          idea: {
            ...idea,
            preview: event.target.result,
          },
        }
      })

    reader.readAsDataURL(image)
  }

  handleRemoveImage = () => {
    this.setState(prevState => {
      const idea = prevState.idea

      return {
        idea: {
          ...idea,
          image: null,
          preview: '',
        },
      }
    })
  }

  render() {
    const { idea, isInputMode, ideaWasDeleted, ideaWasSubmitted } = this.state

    if (!idea) return null
    if (ideaWasDeleted) return <Redirect to={'/notes'} />
    if (ideaWasSubmitted)
      return (
        <Redirect
          to={`/innovationroom/innovation/detail/${this.state.idea.id}`}
        />
      )

    if (isInputMode)
      return (
        <InnovationCreate
          origin="Innovationsstichwort"
          idea={idea}
          toggleView={this.toggleView}
          handleInputChange={this.handleInputChange}
          handleImageChange={this.handleImageChange}
          handleRemoveImage={this.handleRemoveImage}
          submit={this.handleSubmit}
          products={this.state.products}
        />
      )

    return (
      <KeywordOverview
        idea={idea}
        convert={this.convert}
        deleteKeyword={this.handleKeywordDeletion}
        update={() => this.componentDidMount()}
      />
    )
  }
}

export default KeywordConfirmation
