import React, { Component } from 'react'
import IdeaDetail from '../IdeaDetail'

class InnovationIdeaDetail extends Component {
  render() {
    return (
      <IdeaDetail
        key="innovation_idea"
        ideaType="innovation"
        match={this.props.match}
      />
    )
  }
}
export default InnovationIdeaDetail
