import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { toast } from 'react-toastify'
import {
  ProtectedRoute,
  PublicRoute,
  NotificationContainer,
} from './components'
import HttpClient from './utils/HttpClient'
import HttpClientContext from './utils/HttpClientContext'
import routes from './utils/routes'

class App extends Component {
  constructor(props) {
    super(props)
    const httpClient = new HttpClient()

    this.state = {
      httpClient,
      showGlobalLoader: false,
    }

    window.addEventListener('online', this.handleNetworkChange)
    window.addEventListener('offline', this.handleNetworkChange)
    this.history = createBrowserHistory()
    this.history.listen(() => {
      window.scrollTo(0, 0)
    })
  }

  handleNetworkChange = event => {
    switch (event.type) {
      case 'offline':
        toast.error(
          'Sie sind offline. Zur Nutzung von DIPIA ist eine Internetverbindung erforderlich.'
        )
        break

      case 'online':
        toast.success('Sie sind wieder online.')
        break

      default:
        break
    }
  }

  render() {
    return (
      <Router history={this.history}>
        <HttpClientContext.Provider value={this.state.httpClient}>
          {routes.map((route, index) =>
            route.protected ? (
              <ProtectedRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
                roles={route.roles ? route.roles : ['ROLE_USER']}
              />
            ) : (
              <PublicRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            )
          )}
          <NotificationContainer />
        </HttpClientContext.Provider>
      </Router>
    )
  }
}

export default App
