import React, { Component } from 'react'
import Popover from 'react-awesome-popover'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { ReactComponent as EditIcon } from '../../styles/images/pencil.svg'
import { ReactComponent as CheckmarkIcon } from '../../styles/images/check-icon.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-right.svg'
import LoaderIndicator from '../LoaderIndicator'

function AddButton(props) {
  const { handleAdd } = props

  return (
    <button
      className="innovation-detail__add-button innovation-detail__add-button--pink"
      onClick={handleAdd}
    >
      <PlusIcon />
    </button>
  )
}

function EditButton(props) {
  const { className, onClick } = props

  return (
    <button
      className={`innovation-detail__edit-button ${className}`}
      onClick={onClick}
    >
      <EditIcon />
    </button>
  )
}

function SaveButton(props) {
  return (
    <button
      className="innovation-detail__save-button innovation-detail__save-button--pink"
      type="submit"
    >
      <ChevronIcon />
    </button>
  )
}

class TodoList extends Component {
  static contextType = HttpClientContext

  state = {
    isEditing: false,
    newTodo: '',
  }

  enterEditMode = refName => {
    this.setState({ isEditing: true }, () => {
      this.todoInput.focus()
    })
  }

  handleNewTodoChange = event => {
    const {
      target: { value },
    } = event

    this.setState({ newTodo: value })
  }

  saveTodo = async () => {
    const httpClient = this.context
    const { newTodo } = this.state

    if (newTodo == '') return

    await httpClient.post(`/user/todo/new`, JSON.stringify({ todo: newTodo }))

    this.props.refreshData()
    this.setState({
      isEditing: false,
      newTodo: '',
    })
  }

  deleteTodo = async id => {
    const httpClient = this.context
    await httpClient.delete(`/user/todo/${id}`)

    this.props.refreshData()
  }

  toggleTodo = async id => {
    const httpClient = this.context
    await httpClient.post(`/user/todo/${id}/toggle`)

    this.props.refreshData()
  }

  // FIXME: CSS-Classes are taken from InnovationIdeaDetail :(
  // be careful, because right now if you change the styles for one the other changes too
  render() {
    const { todos } = this.props
    const { isEditing, newTodo } = this.state

    return (
      <div className="ceo-dashboard__container">
        <h2 className="notes__list-title">Nächste Schritte - TO DO</h2>
        <p className="ceo-todo-list__title">
          <AddButton handleAdd={this.enterEditMode} />
        </p>

        <ul className="todo-list">
          {isEditing && (
            <form onSubmit={this.saveTodo}>
              <li className="todo-list-item">
                <input
                  type="text"
                  name="newTodo"
                  ref={el => (this.todoInput = el)}
                  value={newTodo}
                  onChange={this.handleNewTodoChange}
                  placeholder="Todo..."
                />

                <SaveButton />
              </li>
            </form>
          )}

          {todos.length > 0 ? (
            todos.map(todo => (
              <li key={todo.id} className="todo-list-item">
                {todo.todo}

                <Popover
                  placement="left"
                  arrow={false}
                  contentClass="innovation-detail__popover-content innovation-detail__popover-content--small"
                >
                  <EditButton className="innovation-detail__edit-button--blue" />
                  <div>
                    <div className="innovation-detail__popover-button-group">
                      <button
                        type="button"
                        onClick={() => this.deleteTodo(todo.id)}
                      >
                        Löschen
                      </button>
                    </div>
                  </div>
                </Popover>
                <button
                  type="button"
                  onClick={() => this.toggleTodo(todo.id)}
                  className={`todo-list__checkmark-button ${
                    todo.done ? 'todo-list__checkmark-button--done' : ''
                  }`}
                >
                  <CheckmarkIcon />
                </button>
              </li>
            ))
          ) : (
            <LoaderIndicator />
          )}
        </ul>
      </div>
    )
  }
}

export default TodoList
