import React from 'react'
import { ReactComponent as CloseIcon } from '../../styles/images/close.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-right.svg'
import { ReactComponent as CameraIcon } from '../../styles/images/camera.svg'

function InnovationCreate(props) {
  const {
    idea,
    origin,
    toggleView,
    handleInputChange,
    handleImageChange,
    handleRemoveImage,
    submit,
    products,
  } = props

  return (
    <div className="keyword__container keyword__container--input">
      {idea.preview && (
        <div className="image-upload-preview image-upload-preview--confirm">
          <img src={idea.preview} alt="Idea Preview" />

          <button
            type="button"
            className="image-upload-preview__remove-button"
            onClick={handleRemoveImage}
          >
            Bild entfernen
          </button>
        </div>
      )}

      <span className="keyword-input__header">
        Neue Innovationsidee
        <button
          type="button"
          className="keyword-input__back-link"
          onClick={toggleView}
        >
          <span className="keyword-input__icon keyword-input__icon--back">
            <CloseIcon />
          </span>
        </button>
      </span>

      <span className="keyword-input__sub-header">aus {origin} erstellen</span>

      <label className="keyword-input__label">
        <span>Name der Innovationsidee</span>
        <input
          type="text"
          name="title"
          value={idea.title}
          onChange={handleInputChange}
          className="keyword-input__input"
        />
      </label>

      <label className="keyword-input__label">
        <span>Kurzbeschreibung der Innovationsidee</span>
        <textarea
          name="description"
          rows="5"
          value={idea.description}
          onChange={handleInputChange}
          className="keyword-input__textarea"
        />
      </label>

      {!idea.preview && (
        <label className="keyword-input__label keyword-input__label--image">
          <span className="keyword-input__image-icon">
            <CameraIcon />
          </span>
          <span>Foto oder Bild hinzufügen</span>
          <input
            type="file"
            accept="image/*"
            name="image"
            onChange={handleImageChange}
          />
        </label>
      )}

      <label className="keyword-input__label">
        <span>Produkt zuordnen</span>
        <select
          name="product"
          value={idea.product}
          onChange={handleInputChange}
        >
          <option value="" disabled hidden>
            Produkt auswählen
          </option>
          {products.map(product => (
            <option key={product.id} value={product.id}>
              {product.title}
            </option>
          ))}
        </select>
      </label>

      <button
        type="button"
        className="keyword-input__button keyword-input__button--confirm"
        onClick={submit}
      >
        <span>Stichwort zur Innovationsidee machen</span>
        <span className="keyword-input__icon keyword-input__icon--confirm">
          <ChevronIcon />
        </span>
      </button>

      <button
        type="button"
        className="keyword-input__button--back"
        onClick={toggleView}
      >
        Abbrechen
      </button>
    </div>
  )
}

export default InnovationCreate
