import React, { Component, Fragment } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { Header, Navigation, CeoNavigation, AdminNavigation, Footer } from '..'
import { ReactComponent as ChevronIconPink } from '../../styles/images/chevron-pink.svg'

class ProtectedRoute extends Component {
  getNavigation = () => {
    const {
      location: { pathname },
    } = this.props

    const isCEORoute = pathname.startsWith('/ceo')
    const isAdminRoute = pathname.startsWith('/admin')

    if (isCEORoute && this.context.isCEOEnabled()) return <CeoNavigation />
    else if (isAdminRoute && this.context.isAdmin()) return <AdminNavigation />
    else return <Navigation />
  }

  getClass = () => {
    const {
      location: { pathname },
    } = this.props

    const isCEORoute = pathname.startsWith('/ceo')
    const isAdminRoute = pathname.startsWith('/admin')

    if (isCEORoute && this.context.isCEOEnabled()) return 'main__ceo'
    else if (isAdminRoute && this.context.isAdmin()) return 'main__admin'
    else return 'main__user'
  }

  render() {
    const {
      component: Component,
      location: { pathname },
      ...rest
    } = this.props
    const httpClient = this.context
    const isUserAuthenticated = httpClient.isUserAuthenticated()

    const isCEORoute = pathname.startsWith('/ceo')

    if (isCEORoute && !httpClient.isCEOEnabled()) {
      console.log('route, ceo disabled')
      return <Redirect to={{ pathname: '/start' }} />
    }

    return (
      <Route
        {...rest}
        render={props => {
          const roles = this.props.roles || ['ROLE_USER']
          return isUserAuthenticated && httpClient.isGranted(roles) ? (
            <Fragment>
              <Header />

              {this.getNavigation()}

              <main className={this.getClass()}>
                <Component {...props} />
              </main>

              <button
                type="button"
                className="button__to-top"
                onClick={() => window.scrollTo(0, 0)}
              >
                <ChevronIconPink />
              </button>

              <Footer />
            </Fragment>
          ) : (
            <Redirect to={{ pathname: '/' }} />
          )
        }}
      />
    )
  }
}

export default withRouter(ProtectedRoute)

// see: https://stackoverflow.com/a/53241270/2000741
ProtectedRoute.contextType = HttpClientContext
