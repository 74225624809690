import React from 'react'
import LoaderIndicator from '../LoaderIndicator'
import Entry from './Entry'

export default props => {
  const { fotonotes, linkParams } = props

  return fotonotes.length > 0 ? (
    <ul className="foto-list">
      {fotonotes.map(idea => (
        <Entry key={idea.id} idea={idea} linkParams={linkParams} />
      ))}
    </ul>
  ) : (
    <LoaderIndicator />
  )
}
