import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import TodoList from './TodoList'

class CeoMisc extends Component {
  static contextType = HttpClientContext

  state = {
    todos: [],
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const httpClient = this.context
    // FIXME: Why do get-routes not work here?
    let todos = await httpClient.get('/user/todos')

    this.setState({
      todos,
    })
  }

  render() {
    const { todos } = this.state

    return (
      <div className="ceo-dashboard__container">
        <TodoList todos={todos} refreshData={this.fetchData} />
      </div>
    )
  }
}

export default CeoMisc
