import React, { Component, Fragment } from 'react'
import { toast } from 'react-toastify'
import { NotificationContainer, LoaderIndicator } from '..'
import IdeaInput from './IdeaInput'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as InnovationIcon } from '../../styles/images/innovation-idea.svg'
import { ReactComponent as OptimisationIcon } from '../../styles/images/optimisation-idea.svg'
import { ReactComponent as ProductIcon } from '../../styles/images/product-idea.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'
import { ReactComponent as CameraIcon } from '../../styles/images/camera.svg'

class StartScreen extends Component {
  static contextType = HttpClientContext

  state = {
    innovationKeyword: {
      title: '',
    },
    optimisationIdea: {
      title: '',
      department: '',
      product: '',
      description: '',
      expanded: false,
      image: null,
      preview: '',
      location: '',
      categories: [],
    },
    productIdea: {
      expanded: false,
      title: '',
      description: '',
      image: null,
      preview: '',
    },
    fotonoteIdea: {
      expanded: false,
      title: '',
      description: '',
      image: null,
      preview: '',
    },
    departments: [],
    products: [],
    categories: [],
    OCategoriesExpand: false,
    loadingIndicator: false,
  }

  async componentDidMount() {
    const httpClient = this.context
    let [departments, products, categories] = await Promise.all([
      httpClient.get('/department/'),
      httpClient.get('/product/'),
      httpClient.get('/category/'),
    ])

    // Add default values to reset to
    departments.unshift({ id: '', name: 'Abteilung' })
    products.unshift({ id: '', title: 'Produkt' })

    this.setState({ departments, products, categories })
  }

  toggleInputArea = type => {
    this.setState(prevState => {
      const prevStateOfType = prevState[type]

      return {
        [type]: {
          ...prevStateOfType,
          expanded: !prevStateOfType.expanded,
        },
      }
    })
  }

  handleImageChange = ({ type, image }) => {
    if (!image) return

    this.setState(prevState => {
      const prevStateOfType = prevState[type]

      return {
        [type]: {
          ...prevStateOfType,
          image,
        },
      }
    })

    this.previewImage({ type, image })
  }

  previewImage = ({ type, image }) => {
    const reader = new FileReader()

    reader.onload = event =>
      this.setState(prevState => {
        const prevStateOfType = prevState[type]

        return {
          [type]: {
            ...prevStateOfType,
            preview: event.target.result,
          },
        }
      })

    reader.readAsDataURL(image)
  }

  removeImage = type => {
    this.setState(prevState => {
      const prevStateOfType = prevState[type]

      return {
        [type]: {
          ...prevStateOfType,
          image: null,
          preview: '',
        },
      }
    })
  }

  createKeywordIdea = async () => {
    if (this.state.innovationKeyword.title === '') {
      return
    }
    const formData = new FormData()
    formData.append(
      'json',
      JSON.stringify({
        title: this.state.innovationKeyword.title,
        confirmationPending: true,
      })
    )

    const httpClient = this.context
    try {
      this.setState({ loadingIndicator: true })
      await httpClient.post('/innovation_idea/new', formData)
      let innovationKeyword = { ...this.state.innovationKeyword }
      innovationKeyword.title = ''
      this.setState({
        innovationKeyword: innovationKeyword,
        loadingIndicator: false,
      })
      toast.success('Innovationsstichwort wurde unter STICHWORTE abgelegt.')
    } catch (error) {
      //TODO error handling
      console.log('fail')
    }
  }

  createOptimisationIdea = async () => {
    let oIdea = this.state.optimisationIdea
    if (oIdea.title === '') {
      return
    }
    const formData = new FormData()
    formData.append(
      'json',
      JSON.stringify({
        title: oIdea.title,
        description: oIdea.description,
        department: oIdea.department,
        product: oIdea.product,
        location: oIdea.location,
        categories: oIdea.categories,
      })
    )
    formData.append('newPictures[]', oIdea.image)

    const httpClient = this.context
    try {
      this.setState({ loadingIndicator: true })
      await httpClient.post('/optimisation_idea/new', formData)
      oIdea = {
        title: '',
        description: '',
        image: null,
        preview: '',
        expanded: false,
        categories: [],
      }
      this.setState({ optimisationIdea: oIdea, loadingIndicator: false })
      toast.success('Optimierungsidee wurde im INNOVATIONSRAUM gespeichert.')
    } catch (error) {
      //TODO error handling
      console.log('fail')
    }
  }

  createProductIdea = async () => {
    let pIdea = this.state.productIdea
    if (pIdea.title === '') {
      return
    }
    const formData = new FormData()
    formData.append(
      'json',
      JSON.stringify({
        title: pIdea.title,
        description: pIdea.description,
      })
    )
    formData.append('newPictures[]', pIdea.image)

    const httpClient = this.context
    try {
      this.setState({ loadingIndicator: true })
      await httpClient.post('/product_idea/new', formData)
      pIdea = {
        title: '',
        description: '',
        image: null,
        preview: '',
        expanded: false,
      }
      this.setState({ productIdea: pIdea, loadingIndicator: false })
      toast.success('Produktidee wurde im INNOVATIONSRAUM gespeichert.')
    } catch (error) {
      //TODO error handling
      console.log('fail')
    }
  }

  createFotonoteIdea = async event => {
    let fIdea = this.state.fotonoteIdea
    const formData = new FormData()
    formData.append(
      'json',
      JSON.stringify({
        title: fIdea.title,
        description: fIdea.description,
      })
    )
    formData.append('newPictures[]', fIdea.image)

    const httpClient = this.context
    try {
      this.setState({ loadingIndicator: true })
      await httpClient.post('/fotonote_idea/new', formData)
      fIdea = {
        title: '',
        description: '',
        image: null,
        preview: '',
        expanded: false,
      }
      this.setState({ fotonoteIdea: fIdea, loadingIndicator: false })
      toast.success('Fotonotiz wurde unter STICHWORTE abgelegt.')
    } catch (error) {
      //TODO error handling
      console.log('fail')
    }
  }

  // for all simple controlled inputs
  handleChange = (type, event) => {
    const {
      target: { name, selectedOptions, value },
    } = event
    let idea = { ...this.state[type] }

    if (selectedOptions) {
      if (selectedOptions.length == 1) {
        idea[name] = selectedOptions[0].value
      } else {
        idea[name] = []
        for (let a = 0; a < selectedOptions.length; a++) {
          idea[name].push(selectedOptions[a].value)
        }
      }
    } else {
      idea[name] = value
    }

    this.setState(prevState => {
      return { [type]: idea }
    })
  }

  render() {
    const { optimisationIdea, productIdea, fotonoteIdea } = this.state

    return (
      <Fragment>
        <p className="start__welcome">
          <span className="start__salutation">
            Hallo{' '}
            <span className="start__salutation-name">
              {this.context.getUser().name}
            </span>
            ,
          </span>
          <span>füge eine neues Stichwort, eine Idee oder Notiz hinzu:</span>
        </p>

        <form onSubmit={this.createKeywordIdea} className="start__idea">
          <label className="start__idea--innovation">
            <InnovationIcon className="start__idea-icon" />

            <input
              type="text"
              name="title"
              value={this.state.innovationKeyword.title}
              onChange={event => this.handleChange('innovationKeyword', event)}
              placeholder="Neues Innovationsstichwort"
              className="start__innovation-input"
            />

            <button className="start__idea-submit" type="submit">
              <ChevronIcon />
            </button>
          </label>
        </form>

        <IdeaInput
          title="Neue Optimierungsidee"
          icon={OptimisationIcon}
          isExpanded={optimisationIdea.expanded}
          toggleArea={() => this.toggleInputArea('optimisationIdea')}
          createIdea={() => this.createOptimisationIdea()}
        >
          <input
            type="text"
            name="title"
            value={this.state.optimisationIdea.title}
            onChange={event => this.handleChange('optimisationIdea', event)}
            placeholder="Titel"
          />

          <textarea
            name="description"
            rows="5"
            placeholder="Problemskizze / Lösungsidee"
            value={this.state.optimisationIdea.description}
            onChange={event => this.handleChange('optimisationIdea', event)}
          />

          <div className="optimisation-properties">
            <label
              htmlFor="categories"
              onClick={() =>
                this.setState({
                  OCategoriesExpand: !this.state.OCategoriesExpand,
                })
              }
            >
              Kategorien
            </label>
            <select
              name="categories"
              multiple
              value={this.state['optimisationIdea']['categories']}
              onChange={event => this.handleChange('optimisationIdea', event)}
              className={this.state.OCategoriesExpand ? ' open' : ''}
            >
              {this.state.categories.map(cat => (
                <option key={cat.id} value={cat.id}>
                  {cat.title}
                </option>
              ))}
            </select>

            <select
              name="department"
              value={this.state['optimisationIdea']['department']}
              onChange={event => this.handleChange('optimisationIdea', event)}
            >
              {this.state.departments.map(dep => (
                <option key={dep.id} value={dep.id}>
                  {dep.name}
                </option>
              ))}
            </select>

            <select
              name="product"
              value={this.state['optimisationIdea']['product']}
              onChange={event => this.handleChange('optimisationIdea', event)}
            >
              {this.state.products.map(product => (
                <option key={product.id} value={product.id}>
                  {product.title}
                </option>
              ))}
            </select>

            <label htmlFor="location">
              Ort:
              <input
                type="text"
                name="location"
                value={this.state.optimisationIdea.location}
                onChange={event => this.handleChange('optimisationIdea', event)}
                placeholder="Ortsbeschreibung, wo genau?"
              />
            </label>
          </div>

          {!optimisationIdea.preview && (
            <label className="image-upload-input">
              <span className="image-upload-input__icon">
                <CameraIcon />
              </span>
              Bild hinzufügen
              <input
                type="file"
                accept="image/*"
                name="optimisationImage"
                onChange={event =>
                  this.handleImageChange({
                    type: 'optimisationIdea',
                    image: event.target.files[0],
                  })
                }
              />
            </label>
          )}

          {optimisationIdea.preview && (
            <div className="image-upload-preview">
              <img
                src={optimisationIdea.preview}
                alt="Optimisation Idea Preview"
              />

              <button
                type="button"
                className="image-upload-preview__remove-button"
                onClick={() => this.removeImage('optimisationIdea')}
              >
                Bild entfernen
              </button>
            </div>
          )}
        </IdeaInput>

        <IdeaInput
          title="Neue Produktidee"
          icon={ProductIcon}
          isExpanded={productIdea.expanded}
          toggleArea={() => this.toggleInputArea('productIdea')}
          createIdea={() => this.createProductIdea()}
        >
          <input
            type="text"
            name="title"
            value={this.state.productIdea.title}
            onChange={event => this.handleChange('productIdea', event)}
            placeholder="Titel"
          />

          <textarea
            name="description"
            rows="5"
            placeholder="Problemskizze / Lösungsidee"
            value={this.state.productIdea.description}
            onChange={event => this.handleChange('productIdea', event)}
          />

          {!productIdea.preview && (
            <label className="image-upload-input">
              <span className="image-upload-input__icon">
                <CameraIcon />
              </span>
              Bild hinzufügen
              <input
                type="file"
                accept="image/*"
                name="productImage"
                onChange={event =>
                  this.handleImageChange({
                    type: 'productIdea',
                    image: event.target.files[0],
                  })
                }
              />
            </label>
          )}

          {productIdea.preview && (
            <div className="image-upload-preview">
              <img src={productIdea.preview} alt="Product Idea Preview" />

              <button
                type="button"
                className="image-upload-preview__remove-button"
                onClick={() => this.removeImage('productIdea')}
              >
                Bild entfernen
              </button>
            </div>
          )}
        </IdeaInput>

        <IdeaInput
          title="Neue Fotonotiz"
          icon={CameraIcon}
          isExpanded={fotonoteIdea.expanded}
          toggleArea={() => this.toggleInputArea('fotonoteIdea')}
          createIdea={() => this.createFotonoteIdea()}
          className="start__idea-fotonote-idea"
        >
          {!fotonoteIdea.preview && (
            <label className="image-upload-input">
              <span className="image-upload-input__icon">
                <CameraIcon />
              </span>
              Bild hinzufügen
              <input
                type="file"
                accept="image/*"
                name="fotonoteImage"
                onChange={event =>
                  this.handleImageChange({
                    type: 'fotonoteIdea',
                    image: event.target.files[0],
                  })
                }
              />
            </label>
          )}

          {fotonoteIdea.preview && (
            <div className="image-upload-preview">
              <img src={fotonoteIdea.preview} alt="Fotonote Idea Preview" />

              <button
                type="button"
                className="image-upload-preview__remove-button"
                onClick={() => this.removeImage('fotonoteIdea')}
              >
                Bild entfernen
              </button>
            </div>
          )}

          <input
            type="text"
            name="title"
            value={this.state.fotonoteIdea.title}
            onChange={event => this.handleChange('fotonoteIdea', event)}
            placeholder="Titel"
          />

          <textarea
            name="description"
            rows="5"
            placeholder="Kurzbeschreibung"
            value={this.state.fotonoteIdea.description}
            onChange={event => this.handleChange('fotonoteIdea', event)}
          />
        </IdeaInput>

        <p className="hint">
          <strong>INFO:</strong> Hier auf der DIPIA-Startseite können Sie Ihre
          Ideen in Text- und Fotoform festhalten, um sie später unter dem Punkt
          STICHWORTE anzuschauen und zu bewerten.
          <br />
          <br />
          Relevante Inhalte überführen Sie dann in den INNOVATIONSRAUM, von dem
          aus Sie Ihr Unternehmen kreativ und innovativ weiterentwickeln.
        </p>

        {this.state.loadingIndicator && <LoaderIndicator global />}

        <NotificationContainer />
      </Fragment>
    )
  }
}

export default StartScreen
