import React, { Component } from 'react'
import { RessourceList } from '../'

class OrganisationList extends Component {
  columns = [
    {
      title: 'Aktiv',
      ident: 'active',
      render: value => (value ? '✓' : 'x'),
    },
    {
      title: 'Name',
      ident: 'name',
    },
  ]

  render() {
    return (
      <RessourceList
        endpoint="organisation"
        columns={this.columns}
        count={12}
      />
    )
  }
}

export default OrganisationList
