import React, { Component, Fragment } from 'react'
import { withRouter, Redirect, Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'

class RessourceSingle extends Component {
  static defaultProps = {
    propagate: () => {},
  }

  constructor(props) {
    super(props)

    this.state = { record: {}, hasError: false, redirectAfterRequest: false }
  }

  async componentDidMount() {
    // fetch record in case of editing
    if (!isEmpty(this.props.match.params)) {
      const {
        endpoint,
        match: {
          params: { id },
        },
      } = this.props
      const httpClient = this.context
      const record = await httpClient.get(`/admin/${endpoint}/${id}`)

      this.setState({ record }, this.props.propagate(record))
    }
  }

  componentDidUpdate() {
    // generate fields when creating a new record
    const keys = Object.values(this.state.record)
    if (this.props.fields.length > keys.length) {
      const record = this.state.record

      this.props.fields
        .filter(f => keys.indexOf(f.ident) == -1)
        .forEach(field => {
          switch (field.type) {
            case 'checkbox':
              record[field.ident] = true
              break
            case 'multiselect':
              record[field.ident] = []
              break
            default:
              record[field.ident] = ''
          }
        })
      this.setState({ record })
    }
  }

  handleChange = event => {
    const { type, name, value, checked } = event.target
    const { record } = this.state

    if (type == 'checkbox') {
      if (value == 'on') {
        record[name] = checked
      } else if (checked) {
        record[name].push(value)
      } else {
        record[name].splice(record[name].indexOf(value), 1)
      }
    } else {
      record[name] = value
    }

    this.setState({ record }, this.props.propagate(record))
  }

  handleSubmit = async event => {
    event.preventDefault()

    let requiredFilled = true
    this.props.fields
      .filter(f =>
        isFunction(f.required) ? f.required(this.state.record) : f.required
      )
      .forEach(f => {
        const value = this.state.record[f.ident]

        if (value === undefined) {
          requiredFilled &= false
          return
        }

        switch (f.type) {
          case 'checkbox':
            requiredFilled &= value
            break
          case 'multiselect':
            requiredFilled &= !isEmpty(value)
            break
          default:
            requiredFilled &= value != ''
            break
        }
      })

    if (!requiredFilled) {
      return
    }

    const {
      endpoint,
      match: {
        params: { id },
      },
    } = this.props
    const httpClient = this.context

    try {
      // differentiate between editing and creating
      const url = `/admin/${endpoint}${id ? `/${id}` : `/`}`
      await httpClient.post(url, JSON.stringify(this.state.record))

      this.setState({ redirectAfterRequest: this.props.redirectAfterRequest })
    } catch (err) {
      this.setState({ hasError: true })
    }
  }

  getInputForField = field => {
    if (
      field.roles &&
      field.roles.length &&
      !this.context.isGranted(field.roles)
    ) {
      return null
    }

    switch (field.type) {
      case 'string':
        return (
          <label key={field.ident}>
            {field.title}
            <input
              type="text"
              name={field.ident}
              value={
                this.state.record[field.ident] !== null
                  ? this.state.record[field.ident]
                  : ''
              }
              onChange={this.handleChange}
            />
          </label>
        )
      case 'checkbox':
        return (
          <label key={field.ident}>
            {field.title}
            <input
              type="checkbox"
              name={field.ident}
              checked={this.state.record[field.ident]}
              onChange={this.handleChange}
            />
          </label>
        )
      case 'text':
        return (
          <label key={field.ident}>
            {field.title}
            <textarea
              type="checkbox"
              name={field.ident}
              value={
                this.state.record[field.ident] !== null
                  ? this.state.record[field.ident]
                  : ''
              }
              onChange={this.handleChange}
            />
          </label>
        )
      case 'multiselect':
        return (
          <Fragment key={field.ident}>
            {field.title}:
            {field.choices.map(choice => (
              <label key={choice.value}>
                {choice.title}
                <input
                  type="checkbox"
                  name={field.ident}
                  value={choice.value}
                  checked={
                    this.state.record[field.ident] &&
                    this.state.record[field.ident].indexOf(choice.value) > -1
                  }
                  onChange={this.handleChange}
                />
              </label>
            ))}
          </Fragment>
        )
      case 'dropdown':
        return (
          <label key={field.ident}>
            {field.title}
            <select
              name={field.ident}
              value={this.state.record[field.ident]}
              onChange={this.handleChange}
            >
              <option key="none" value="">
                ---
              </option>
              {field.choices.map(choice => (
                <option key={choice.value} value={choice.value}>
                  {choice.title}
                </option>
              ))}
            </select>
          </label>
        )
      default:
        break
    }
  }

  render() {
    const { record, redirectAfterRequest } = this.state
    const { fields, endpoint } = this.props

    if (redirectAfterRequest) {
      return <Redirect to={`/admin/${endpoint}`} />
    }

    return (
      !isEmpty(record) && (
        <form onSubmit={this.handleSubmit}>
          {this.state.hasError && (
            <p style={{ color: 'red' }}>Request failed</p>
          )}

          {fields.map(field => this.getInputForField(field))}

          <div className="admin-area__button-group">
            <Link to={`/admin/${endpoint}`}>Zurück zur Liste</Link>

            <button type="submit" className="admin-area__button-save">
              Speichern
              <ChevronIcon />
            </button>
          </div>
        </form>
      )
    )
  }
}

export default withRouter(RessourceSingle)

RessourceSingle.contextType = HttpClientContext
