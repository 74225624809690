import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { ReactComponent as ProductIcon } from '../../styles/images/product-idea.svg'
import { Pagination, CeoToolbar, IdeaList } from '..'

class InnovationRoomProduct extends Component {
  static contextType = HttpClientContext

  state = {
    latestProductIdeas: [],
    page: 0,
    count: 8,
    departmentFilter: '',
  }

  constructor(props) {
    super(props)

    const { location } = props

    if (location.linkParams) {
      const { page } = location.linkParams
      this.state = { ...this.state, ...{ page } }
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const httpClient = this.context

    const { page, count, departmentFilter } = this.state
    let fetchUrl = '/product_idea/latest'
    fetchUrl += `?page=${page}`
    fetchUrl += `&count=${count}`

    if (this.context.isUserCEO() && departmentFilter) {
      fetchUrl += `&department=${departmentFilter}`
    }

    const latestProductIdeas = await httpClient.get(fetchUrl)

    this.setState({
      latestProductIdeas: latestProductIdeas.entities,
      total: latestProductIdeas.pagination.count,
    })
  }

  paginate = page => {
    this.setState({ page }, this.fetchData)
  }

  filterByDepartment = departmentFilter => {
    this.setState({ departmentFilter }, this.fetchData)
  }

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()

    return (
      <Fragment>
        <section className="innovationRoom__section">
          <h2 className="innovationRoom__title">Produktideen</h2>
          <div className="innovationRoom__new-idea">
            <ProductIcon />
            {!isCeo && (
              <Link
                to="/innovationroom/product/new"
                className="innovationRoom__new-idea-button"
              >
                <span>Neue Produktidee</span>
                <span className="innovationRoom__new-idea-icon">
                  <PlusIcon />
                </span>
              </Link>
            )}
          </div>
          {isCeo && (
            <CeoToolbar
              filterByDepartment={this.filterByDepartment}
              currentDepFilter={this.state.departmentFilter}
            />
          )}

          <Pagination
            paginate={this.paginate}
            page={this.state.page}
            count={this.state.count}
            total={this.state.total}
          >
            {/*<button type="button" className="innovationRoom__sorting">
              Nach Datum
            </button>*/}
            <IdeaList
              key="product"
              ideas={this.state.latestProductIdeas}
              linkParams={{
                page: this.state.page,
              }}
            />
          </Pagination>
        </section>
      </Fragment>
    )
  }
}

export default InnovationRoomProduct
