import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { Pagination } from '../index'
import { ReactComponent as SearchIcon } from '../../styles/images/search-glass.svg'
import SearchEntry from './Entry'

class Search extends Component {
  state = {
    page: 0,
    perPage: 12,
    pagination: {
      count: 0,
    },
    entities: [],
  }

  componentDidMount() {
    this.doSearch()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.term !== this.props.match.params.term) {
      this.setState({ page: 0 }, this.doSearch())
    }
  }

  async doSearch() {
    const httpClient = this.context

    const { perPage, page } = this.state
    const result = await httpClient.post(
      `/search/?count=${perPage}&page=${page}`,
      JSON.stringify({ term: this.props.match.params.term })
    )

    this.setState({ ...result })
  }

  paginate = page => this.setState({ page }, this.doSearch)

  render() {
    const {
      pagination: { count },
      page,
      perPage,
      entities,
    } = this.state

    const {
      match: {
        params: { term },
      },
    } = this.props

    return (
      <div>
        <div className={'search-header'}>
          <SearchIcon className={'search-header__icon'} />
          <div className={'search-header__title'}>
            <h2>Suchergebnisse für:</h2>
            <h3 className={'search-header__title__search-term'}>
              &bdquo;{term}&ldquo;
            </h3>
          </div>
        </div>
        <Pagination
          count={perPage}
          page={page}
          total={count}
          paginate={newPage => this.paginate(newPage)}
        >
          {entities.map(r => (
            <SearchEntry key={r.id} result={r} />
          ))}
        </Pagination>
      </div>
    )
  }
}

export default withRouter(Search)

Search.contextType = HttpClientContext
