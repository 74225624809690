import React, { Component } from 'react'
import { IdeaEditForm } from '../Forms'
import { Redirect } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'

const sourceTypeRouteMap = {
  fotonote: 'fotonote_idea',
  keyword: 'innovation_idea',
}

const targetTypeRouteMap = {
  1: {
    save: '/innovation_idea/:id/edit?convert=1',
    redirect: '/innovationroom/innovation/detail/:id',
  },
  2: {
    save: '/optimisation_idea/:id/edit?convert=1',
    redirect: '/innovationroom/optimisation/detail/:id',
  },
  3: {
    save: '/product_idea/:id/edit?convert=1',
    redirect: '/innovationroom/product/detail/:id',
  },
}

class Convert extends Component {
  static contextType = HttpClientContext

  constructor(props) {
    super(props)
    this.targetType = parseInt(this.props.match.params.targetType)
  }

  state = {
    idea: false,
    redirectAfterCreate: false,
  }

  async componentDidMount() {
    const {
      match: {
        params: { id, sourceType },
      },
    } = this.props
    const httpClient = this.context
    const idea = await httpClient.get(
      `/${sourceTypeRouteMap[sourceType]}/${id}`
    )

    this.setState({ idea })
  }

  doConvert = async idea => {
    if (idea.title === '') {
      return
    }

    const form = new FormData()
    idea.confirmationPending = false
    form.append('json', JSON.stringify(idea))
    idea.newPictures.map(image => form.append('newPictures[]', image))

    const httpClient = this.context
    const targetTypeRoute = targetTypeRouteMap[this.targetType]

    await httpClient.post(targetTypeRoute.save.replace(':id', idea.id), form)

    this.setState({ redirectAfterCreate: true })
  }

  render() {
    const { idea, redirectAfterCreate } = this.state
    const { sourceType } = this.props.match.params

    const targetTypeRoute = targetTypeRouteMap[this.targetType]

    if (redirectAfterCreate) {
      return <Redirect to={targetTypeRoute.redirect.replace(':id', idea.id)} />
    }

    if (!idea) {
      return <div />
    }

    return (
      <IdeaEditForm
        save={(isNew, idea) => this.doConvert(idea)}
        idea={idea}
        backLink={`/notes/${sourceType}/${idea.id}/confirm`}
        isNew={true}
        newType={this.targetType}
      />
    )
  }
}

export default Convert
