import React, { Component } from 'react'
import loaderImage from '../../styles/images/blue-wait.gif'

export default class LoaderIndicator extends Component {
  render() {
    const { global, loaded, children } = this.props

    const overlayClass = global ? '__overlay' : ''

    return !loaded ? (
      <div className={`loader-indicator${overlayClass}`}>
        {global && (
          <div className={`loader-indicator${overlayClass}__overlay`} />
        )}
        <img
          className={`loader-indicator${overlayClass}__image`}
          src={loaderImage}
          alt={''}
        />
      </div>
    ) : (
      children
    )
  }
}
