import React, { Component } from 'react'
import Rating from 'react-rating'
import { toast } from 'react-toastify'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as StarEmptyIcon } from '../../styles/images/star-empty.svg'
import { ReactComponent as StarFullIcon } from '../../styles/images/star-full.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'

class OptimisationIdeaRating extends Component {
  static contextType = HttpClientContext
  state = {
    rating: null,
    comment: '',
  }

  componentDidMount() {
    this.triggerRerender()
  }

  // hacky solution to solve height issues on initial render and
  // when comment section expands inside(!) the carousel
  triggerRerender = () => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 0)
  }

  handleRatingChange = value => {
    this.setState({ rating: value })
  }

  handleCommentChange = event => {
    this.setState({ comment: event.target.value })
  }

  handleRatingSubmission = () => {
    const { idea } = this.props
    const { rating, comment } = this.state

    // do not submit if rating has not been actively set
    if (rating === null || comment == '') {
      toast.warn('Bitte geben Sie eine Bewertung und einen Kommentar ab.')
      return
    }

    this.props.handleRating({ id: idea.id, value: rating, comment })
  }

  getCategories = idea =>
    this.props.categories.filter(c => idea.categories.indexOf(c.id) > -1)

  getDepartment = idea =>
    this.props.departments.find(d => d.id === idea.department)

  getProduct = idea => this.props.products.find(p => p.id === idea.product)

  render() {
    const { idea } = this.props

    const categories = this.getCategories(idea)
    const department = this.getDepartment(idea)
    const product = this.getProduct(idea)

    return (
      <div>
        <span className="innovationRoom__rating-title">{idea.title}</span>
        <p className="innovationRoom__rating-description">{idea.description}</p>

        <div className="innovationRoom__rating-input">
          <span className="innovationRoom__rating-input-text">
            Dringlichkeit
          </span>
          <Rating
            className="innovationRoom__rating-stars"
            emptySymbol={<StarEmptyIcon />}
            fullSymbol={<StarFullIcon />}
            onChange={this.handleRatingChange}
            initialRating={this.state.rating}
          />
        </div>

        <div className="innovationRoom__rating-comment">
          <p>
            <span>Kommentar / Lösungsvorschlag</span>
          </p>
          <div>
            <textarea
              rows="5"
              placeholder="Mein Kommentar..."
              value={this.state.comment}
              onChange={this.handleCommentChange}
            />

            <button
              className="innovationRoom__rating-submit"
              onClick={this.handleRatingSubmission}
            >
              <ChevronIcon />
            </button>
          </div>
        </div>

        <dl>
          {categories.length > 0 && [
            <dt key="rating_category_term">Kategorie:</dt>,
            <dd key="rating_category_desc">
              {categories.map(category => category.title).join(', ')}
            </dd>,
          ]}
          {department && [
            <dt key="rating_department_term">Abteilung:</dt>,
            <dd key="rating_department_desc">{department.name}</dd>,
          ]}
          {product && [
            <dt key="rating_product_term">Produkt:</dt>,
            <dd key="rating_product_desc">{product.title}</dd>,
          ]}
          {idea.location && [
            <dt key="rating_location_term">Ort:</dt>,
            <dd key="rating_location_desc">{idea.location}</dd>,
          ]}
        </dl>
      </div>
    )
  }
}

export default OptimisationIdeaRating
