import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import { RessourceList } from '../'
import USER_ROLES from '../../utils/UserRoles'

const ROLE_MAPPING = {
  ROLE_APPLICATION_ADMIN: 'App-Admin',
  ROLE_ORGANISATION_ADMIN: 'Org-Admin',
  ROLE_ORGANISATION_CEO: 'CEO',
  ROLE_USER: 'Nutzer',
}

class UserList extends Component {
  state = {
    columns: [
      {
        title: 'Aktiv',
        ident: 'active',
        render: value => (value ? '✓' : 'x'),
      },
      {
        title: 'ID',
        ident: 'id',
      },
      {
        title: 'Email',
        ident: 'email',
      },
      {
        title: 'Vorname',
        ident: 'firstName',
      },
      {
        title: 'Nachname',
        ident: 'lastName',
      },
      {
        title: 'Benachrichtungen',
        ident: 'notifications',
        render: value => (value ? '✓' : 'x'),
      },
      {
        title: 'Rollen',
        ident: 'roles',
        render: roles => roles.map(role => ROLE_MAPPING[role]).join(', '),
      },
    ],
  }

  async componentDidMount() {
    const httpClient = this.context

    const columns = this.state.columns

    if (this.context.isAppAdmin()) {
      const organisations = await httpClient.get('/admin/organisation/all')

      columns.push({
        title: 'Organisation',
        ident: 'organisation',
        roles: [USER_ROLES.APP_ADMIN],
        render: id => organisations.find(o => o.id === id).name,
      })
    }

    const departments = await httpClient.get('/admin/department/all')
    columns.push({
      title: 'Abteilung',
      ident: 'department',
      render: id => departments.find(d => d.id === id).name,
    })
    this.setState({ columns })
  }

  render() {
    return (
      <RessourceList endpoint="user" columns={this.state.columns} count={12} />
    )
  }
}

export default UserList

UserList.contextType = HttpClientContext
