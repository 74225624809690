import React from 'react'
import { ToastContainer } from 'react-toastify'

function NotificationContainer() {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable={false}
      pauseOnHover={false}
    />
  )
}

export default NotificationContainer
