import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as Signet } from '../../styles/images/dipia-logo-signet.svg'

class Footer extends Component {
  render() {
    const user = this.context.getUser()

    return (
      <footer>
        <span className="footer__org-title">{user.organisation.name}</span>
        <div className="footer__separator" />
        <span className="footer__app-title">
          DIP<span className="footer__special-char">I</span>A . Digitaler
          persönlicher Innovationsassistent
        </span>
        <span className="footer__app-subtitle">Zukunft entwickeln</span>

        <div className="footer__signet">
          <Signet />
        </div>
      </footer>
    )
  }
}

export default Footer

Footer.contextType = HttpClientContext
