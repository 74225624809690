import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as MenuIcon } from '../../styles/images/menu-icon.svg'
import { ReactComponent as Signet } from '../../styles/images/dipia-logo-signet.svg'
import { ReactComponent as LinkIcon } from '../../styles/images/link-extern.svg'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'

class BurgerMenu extends Component {
  static contextType = HttpClientContext

  state = {
    expand: false,
  }

  closeOverlay = () => {
    document.body.classList.remove('overlay-open')
    this.setState({ expand: false })
  }

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const isAdmin = this.context.isAdmin()

    return [
      <button
        key="opener"
        type="button"
        className="header__button header__button--menu"
        onClick={() =>
          this.setState({ expand: !this.state.expand }, () => {
            if (this.state.expand) {
              document.body.classList.add('overlay-open')
            } else {
              this.closeOverlay()
            }
          })
        }
      >
        <MenuIcon />
      </button>,

      <div
        key="overlay"
        className={`menu__overlay${
          this.state.expand ? ' menu__overlay-open' : ''
        }`}
      >
        <div className="menu__container">
          <div className="menu__signet">
            <Signet />
          </div>

          <ul className="menu__list">
            <li>
              <Link
                to={isCeo ? '/ceo/favorites' : '/start'}
                className="menu__list-item"
                onClick={() => this.closeOverlay()}
              >
                {isCeo ? 'Favoriten' : 'Start'}
              </Link>
            </li>
            <li>
              <Link
                to={`${isCeo ? '/ceo' : ''}/notes`}
                className="menu__list-item"
                onClick={() => this.closeOverlay()}
              >
                Stichworte & Fotonotizen
              </Link>
            </li>
            <li>
              <Link
                to={`${isCeo ? '/ceo' : ''}/innovationroom/innovation`}
                className="menu__list-item"
                onClick={() => this.closeOverlay()}
              >
                Übersicht Innovationsideen
              </Link>
            </li>
            <li>
              <Link
                to={`${isCeo ? '/ceo' : ''}/innovationroom/optimisation`}
                className="menu__list-item"
                onClick={() => this.closeOverlay()}
              >
                Übersicht Optimierungsideen
              </Link>
            </li>
            <li className="menu__list-divide">
              <Link
                to={`${isCeo ? '/ceo' : ''}/innovationroom/product`}
                className="menu__list-item"
                onClick={() => this.closeOverlay()}
              >
                Übersicht Produktideen
              </Link>
            </li>
            {!isCeo && [
              <li key="new_innovation">
                <Link
                  to="/innovationroom/innovation/new"
                  className="menu__list-item"
                  onClick={() => this.closeOverlay()}
                >
                  Neue Innovationsidee
                  <span>
                    <PlusIcon />
                  </span>
                </Link>
              </li>,
              <li key="new_optimisation">
                <Link
                  to="/innovationroom/optimisation/new"
                  className="menu__list-item"
                  onClick={() => this.closeOverlay()}
                >
                  Neue Optimierungsidee
                  <span>
                    <PlusIcon />
                  </span>
                </Link>
              </li>,
              <li key="new_product" className="menu__list-divide">
                <Link
                  to="/innovationroom/product/new"
                  className="menu__list-item"
                  onClick={() => this.closeOverlay()}
                >
                  Neue Produktidee
                  <span>
                    <PlusIcon />
                  </span>
                </Link>
              </li>,
            ]}
            <li>
              <Link
                to="//dipia.ai/ueber-dipia/"
                className="menu__list-item menu__list-item-pink"
                target="_blank"
              >
                Was ist DIPIA? &raquo; Die Idee dahinter
                <LinkIcon />
              </Link>
            </li>
            <li className="menu__list-divide">
              <Link
                to="//dipia.ai/wissen"
                className="menu__list-item menu__list-item-pink"
                target="_blank"
              >
                Zum DIPIA Wissensraum
                <LinkIcon />
              </Link>
            </li>
            <li>
              <Link
                to="//dipia.ai/kontakt/"
                className="menu__list-item"
                target="_blank"
              >
                Hilfe & Kontakt
                <LinkIcon />
              </Link>
            </li>
            <li>
              <Link
                to="//dipia.ai/datenschutz-impressum/"
                className="menu__list-item"
                target="_blank"
              >
                Datenschutz & Impressum
                <LinkIcon />
              </Link>
            </li>
            <li>
              <Link
                to="//dipia.ai/feedback"
                className="menu__list-item"
                target="_blank"
              >
                Feedback
                <LinkIcon />
              </Link>
            </li>
            {isAdmin && (
              <li>
                <Link to="/admin/user" className="menu__list-item">
                  Admin-Area
                </Link>
              </li>
            )}
            <li>
              <button
                type="button"
                onClick={() => this.context.logoutUser()}
                className="menu__list-item"
              >
                Ausloggen
              </button>
            </li>
          </ul>
        </div>
      </div>,
    ]
  }
}

export default BurgerMenu
