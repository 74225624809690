import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TextareaAutosize from 'react-autosize-textarea'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as CloseIcon } from '../../styles/images/close-new.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'
import { ReactComponent as CameraIcon } from '../../styles/images/camera.svg'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { IdeaTypes } from '../../utils/Ideas'
import ImageUrlGenerator from '../../utils/ImageUrlGenerator'
import { Pagination } from '..'

const ideaDefaults = {
  type: 0,
  id: 0,
  title: '',
  headerpicture: false,
  description: '',
  keywords: [],
  pictures: [],
  newPictures: [],
  previews: [],
  department: '',
  product: '',
  location: '',
  categories: [],
}

const dict = {
  true: {
    containerTitle: 'Erstellen',
    title: 'Neue {IDEA_TYPE}',
    button: '{IDEA_TYPE} erstellen',
  },
  false: {
    containerTitle: 'Bearbeiten',
    title: '{IDEA_TYPE} bearbeiten',
    button: '{IDEA_TYPE} speichern',
  },
}

class IdeaEdit extends Component {
  static contextType = HttpClientContext

  static propTypes = {
    save: PropTypes.func.isRequired,
    backLink: PropTypes.string,
    idea: PropTypes.object,
    isNew: PropTypes.bool,
    newType: PropTypes.number,
  }

  constructor(props) {
    super(props)

    const idea = {
      ...ideaDefaults,
      ...{ newPictures: [], previews: [] },
      ...props.idea,
    }
    this.sourceType = idea.type

    if (props.newType) {
      idea.type = parseInt(props.newType)
    }

    this.isNew = void 0 !== props.isNew ? props.isNew : 0 === idea.id
    this.ideaTypeTitle = IdeaTypes.fromIdea(idea)

    if (idea.pictures.length) {
      idea.headerpicture = idea.pictures[0]
    }
    this.state.picturesTotal = idea.pictures.length

    this.state.idea = idea
    this.picturesFetch()
  }

  state = {
    products: [],
    departments: [],
    categories: [],
    idea: { ...ideaDefaults },
    pictures: [],
    picturesPage: 0,
    picturesCount: 2,
  }

  async componentDidMount() {
    const httpClient = this.context
    let [departments, products, categories] = await Promise.all([
      httpClient.get('/department/'),
      httpClient.get('/product/'),
      httpClient.get('/category/'),
    ])

    // Add default value to reset to
    departments.unshift({ id: '', name: 'Abteilung' })
    products.unshift({ id: '', title: 'Produkt' })

    this.setState({ departments, products, categories })
  }

  handleChange = event => {
    let {
      target: { name, selectedOptions, value, multiple },
    } = event

    if (selectedOptions) {
      if (!multiple) {
        value = selectedOptions[0].value
      } else {
        value = []
        for (let a = 0; a < selectedOptions.length; a++) {
          value.push(selectedOptions[a].value)
        }
      }
    }

    this.setState(prevState => {
      return {
        idea: {
          ...prevState.idea,
          [name]: value,
        },
      }
    })
  }

  handleImageChange = event => {
    let image = event.target.files[0]
    if (!image) return

    this.setState(prevState => {
      const idea = prevState.idea
      idea.newPictures.push(image)
      return { idea }
    })
    this.previewImage(image)
  }

  removeImage = idx =>
    this.setState(prevState => {
      const idea = prevState.idea
      idea.newPictures.splice(idx, 1)
      idea.previews.splice(idx, 1)
      return { idea }
    })

  previewImage = image => {
    const reader = new FileReader()

    reader.onload = event =>
      this.setState(prevState => {
        const idea = prevState.idea

        idea.previews.push(event.target.result)

        return { idea }
      })

    reader.readAsDataURL(image)
  }

  handleRemoveImage = () => {
    this.setState(prevState => {
      const idea = prevState.idea

      return {
        idea: {
          ...idea,
          image: null,
          preview: '',
        },
      }
    })
  }

  handleKeywords = event => {
    const {
      target: { value },
    } = event

    this.setState(prevState => {
      return {
        idea: {
          ...prevState.idea,
          keywords: value.split(','),
        },
      }
    })
  }

  picturesFetch = async () => {
    const { picturesPage, picturesCount, idea } = this.state
    const offset = picturesPage * picturesCount + 1

    const pictures = idea.pictures.slice(offset, offset + picturesCount)

    this.setState({
      pictures,
    })
  }

  picturesPaginate = picturesPage => {
    this.setState({ picturesPage }, this.picturesFetch)
  }

  render() {
    const { idea, pictures } = this.state
    const { backLink, save } = this.props

    if (!idea) {
      return <div />
    }

    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()

    return (
      <form onSubmit={() => save(this.isNew, idea)}>
        <div className="keyword__container convert-mode">
          <span className="keyword-overview__header">
            {dict[this.isNew].containerTitle.replace(
              '{IDEA_TYPE}',
              this.ideaTypeTitle
            )}
            {backLink && (
              <Link to={backLink} className="keyword-overview__back-link">
                <span className="keyword-overview__icon keyword-overview__icon--back">
                  <CloseIcon />
                </span>
              </Link>
            )}
          </span>

          <span className="keyword-overview__title">
            {dict[this.isNew].title.replace('{IDEA_TYPE}', this.ideaTypeTitle)}
          </span>

          {idea.headerpicture && (
            <img
              className="keyword-overview__image"
              src={`/uploads/images/${ImageUrlGenerator(
                idea.headerpicture.file,
                idea.organisationSlug
              )}`}
              alt={idea.headerpicture.file}
            />
          )}

          <label className="keyword-input__label">
            <span>Titel/Name</span>
            <input
              type="text"
              name="title"
              value={idea.title}
              onChange={this.handleChange}
            />
          </label>

          <label className="keyword-input__label">
            <span>Kurzbeschreibung</span>
            <TextareaAutosize
              className="keyword-overview__title__input"
              name="description"
              ref={el => (this.titleInput = el)}
              value={idea.description}
              onChange={this.handleChange}
            />
          </label>

          <label className="keyword-input__label">
            <span>Stichworte (kommagetrennt):</span>
            <input
              type="text"
              name="keywords"
              value={idea.keywords.join(',')}
              onChange={this.handleKeywords}
            />
          </label>

          {IdeaTypes.isOptimisation(idea) && (
            <div className="optimisation-properties">
              <label
                htmlFor="categories"
                onClick={() =>
                  this.setState({
                    OCategoriesExpand: !this.state.OCategoriesExpand,
                  })
                }
              >
                Kategorien
              </label>

              <select
                name="categories"
                multiple
                value={idea.categories}
                onChange={this.handleChange}
                className={this.state.OCategoriesExpand ? ' open' : ''}
              >
                {this.state.categories.map(cat => (
                  <option key={cat.id} value={cat.id}>
                    {cat.title}
                  </option>
                ))}
              </select>

              <select
                name="department"
                value={idea.department}
                onChange={this.handleChange}
              >
                {this.state.departments.map(dep => (
                  <option key={dep.id} value={dep.id}>
                    {dep.name}
                  </option>
                ))}
              </select>

              <select
                name="product"
                value={idea.product !== null ? idea.product : ''}
                onChange={this.handleChange}
              >
                {this.state.products.map(product => (
                  <option key={product.id} value={product.id}>
                    {product.title}
                  </option>
                ))}
              </select>

              <label htmlFor="location">
                Ort:
                <input
                  type="text"
                  name="location"
                  value={idea.location !== null ? idea.location : ''}
                  onChange={this.handleChange}
                  placeholder="Ortsbeschreibung, wo genau?"
                />
              </label>
            </div>
          )}

          <span className="keyword-input__label keyword-input__label--image">
            <span className="keyword-overview__upload-button keyword-overview__upload-button--standalone keyword-overview__upload-button--blue">
              <CameraIcon />
            </span>
            <span className="keyword-overview__add-button keyword-overview__add-button--standalone">
              <label>
                <PlusIcon />
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={this.handleImageChange}
                />
              </label>
            </span>
          </span>

          <Pagination
            key="pictures_pagination"
            paginate={this.picturesPaginate}
            page={this.state.picturesPage}
            count={this.state.picturesCount}
            total={this.state.picturesTotal}
          >
            {pictures.map((picture, index) => (
              <span className="keyword-input__label" key={picture.id}>
                <img
                  key={picture.id}
                  className="innovation-detail__detail-image"
                  src={`/uploads/images/${ImageUrlGenerator(
                    picture.file,
                    idea.organisationSlug
                  )}`}
                  alt={`${idea.title} Foto ${index}`}
                />
              </span>
            ))}
          </Pagination>

          {idea.previews.map((pictureUrl, index) => (
            <div className="image-upload-preview" key={index}>
              <img src={pictureUrl} alt="Idea Preview" />
              <button
                type="button"
                className="image-upload-preview__remove-button"
                onClick={() => this.removeImage(index)}
              >
                Bild entfernen
              </button>
            </div>
          ))}

          {!isCeo && (
            <button
              className="keyword-overview__button keyword-overview__button--confirm"
              type="submit"
            >
              <span>
                {dict[this.isNew].button.replace(
                  '{IDEA_TYPE}',
                  this.ideaTypeTitle
                )}
              </span>
              <span className="keyword-overview__icon keyword-overview__icon--confirm">
                <ChevronIcon />
              </span>
            </button>
          )}
        </div>
      </form>
    )
  }
}

export default IdeaEdit
