import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as FavoriteIcon } from '../../styles/images/star-full.svg'
import { ReactComponent as InnovationIcon } from '../../styles/images/innovation-idea.svg'
import { ReactComponent as OptimisationIcon } from '../../styles/images/optimisation-idea.svg'
import { ReactComponent as ProductIcon } from '../../styles/images/product-idea.svg'
import { IdeaTypes } from '../../utils/Ideas'
import ImageUrlGenerator from '../../utils/ImageUrlGenerator'

class IdeaEntry extends Component {
  getIcon = idea => {
    switch (idea.type) {
      case IdeaTypes.INNOVATION:
        return <InnovationIcon />

      case IdeaTypes.OPTIMISATION:
        return <OptimisationIcon />

      case IdeaTypes.PRODUCT:
        return <ProductIcon />

      default:
        return null
    }
  }

  render() {
    const imagePath = '/uploads/images/'
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const { idea, location, linkParams } = this.props
    const ideaTypeRoute = IdeaTypes.appRouteFromIdea(idea)
    const isFavorite = idea.isFavorite
    const inFavorite = location.pathname.includes('/favorites')

    return (
      <li key={idea.id} className="idea__list-item">
        <Link
          to={{
            pathname: `${
              isCeo
                ? `/ceo${inFavorite ? '/favorites' : '/innovationroom'}`
                : '/innovationroom'
            }${ideaTypeRoute}/detail/${idea.id}`,
            linkParams,
          }}
        >
          <span className="idea__list-title">
            {inFavorite && (
              <span className="idea__list-typeicon">{this.getIcon(idea)}</span>
            )}
            {idea.title}
            {isFavorite && isCeo && (
              <span className="idea__list-favorite">
                <FavoriteIcon />
              </span>
            )}
          </span>

          {idea.originTitle && (
            <span className="idea__list-keyword">
              <span>Ursprungsstichwort:</span>
              <span>{idea.originTitle}</span>
            </span>
          )}

          <p className="idea__list-description">
            <span className={'idea__list-description__title'}>
              {idea.description.length > 160
                ? idea.description.slice(0, 160) + '...'
                : idea.description}
            </span>
            {idea.pictures.length > 0 ? (
              <span className={'idea__list-description__image'}>
                <img
                  src={
                    imagePath +
                    ImageUrlGenerator(
                      idea.pictures[0]['file'],
                      idea.organisationSlug
                    )
                  }
                  alt={`Bild für ${idea.title}`}
                />
              </span>
            ) : null}
          </p>

          {/*<span className="idea__list-detail-button">
            <span>Zum Eintrag</span>
            <span className="idea__list-chevron">
              <ChevronIcon />
            </span>
          </span>
          */}
        </Link>
      </li>
    )
  }
}

export default withRouter(IdeaEntry)

IdeaEntry.contextType = HttpClientContext
