import React, { Component } from 'react'
import { RessourceSingle } from '../'
import HttpClientContext from '../../utils/HttpClientContext'
import USER_ROLES from '../../utils/UserRoles'

class UserSingle extends Component {
  static contextType = HttpClientContext

  state = {
    fields: [
      {
        title: 'Aktiv',
        ident: 'active',
        type: 'checkbox',
      },
      {
        title: 'Email',
        ident: 'email',
        type: 'string',
        required: true,
      },
      {
        title: 'Password',
        ident: 'password',
        type: 'string',
        required: record => !record.id,
      },
      {
        title: 'Vorname',
        ident: 'firstName',
        type: 'string',
      },
      {
        title: 'Nachname',
        ident: 'lastName',
        type: 'string',
      },
      {
        title: 'Benachrichtungen',
        ident: 'notifications',
        type: 'checkbox',
      },
    ],
    departments: [],
    organisation: false,
  }

  async componentWillMount() {
    const httpClient = this.context

    const roles = [
      {
        title: 'CEO',
        value: USER_ROLES.CEO,
      },
      {
        title: 'Org-Admin',
        value: USER_ROLES.ORG_ADMIN,
      },
    ]
    const fields = this.state.fields

    if (httpClient.isAppAdmin()) {
      const organisations = await httpClient.get('/admin/organisation/all')

      fields.push({
        title: 'Organisation',
        ident: 'organisation',
        type: 'dropdown',
        required: true,
        choices: organisations.map(org => ({ title: org.name, value: org.id })),
      })

      roles.push({
        title: 'App-Admin',
        value: USER_ROLES.APP_ADMIN,
      })
    }
    const departments = await httpClient.get('/admin/department/all')

    fields.push({
      title: 'Abteilung',
      ident: 'department',
      type: 'dropdown',
      required: true,
      choices: [],
    })

    fields.push({
      title: 'Rollen',
      ident: 'roles',
      type: 'multiselect',
      choices: roles,
    })

    this.setState({ departments, fields }, this.fillDepartments)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.organisation !== this.state.organisation) {
      this.fillDepartments()
    }
  }

  fillDepartments = () => {
    const fields = this.state.fields
    const departments = fields.find(d => d.ident === 'department')

    if (!departments) {
      return
    }

    const httpClient = this.context
    const choices = !httpClient.isAppAdmin()
      ? this.state.departments
      : this.state.departments.filter(
          d => d.organisation == this.state.organisation
        )

    departments.choices = choices.map(c => ({ title: c.name, value: c.id }))

    this.setState({ fields })
  }

  render() {
    return (
      <RessourceSingle
        endpoint="user"
        fields={this.state.fields}
        propagate={record =>
          this.setState({ organisation: record.organisation })
        }
        redirectAfterRequest
      />
    )
  }
}

export default UserSingle
