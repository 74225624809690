import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import { KeywordList, FotonotesList, Pagination, IdeaList } from '..'

class CeoFavorites extends Component {
  static contextType = HttpClientContext

  state = {
    keywords: [],
    keywordsCount: 12,
    keywordsPage: 0,
    fotonotes: [],
    fotonotesCount: 9,
    fotonotesPage: 0,
    other: [],
    otherCount: 3,
    otherPage: 0,
  }

  constructor(props) {
    super(props)

    const { location } = props

    if (location.linkParams) {
      const { keywordsPage, fotonotesPage, otherPage } = location.linkParams
      this.state = {
        ...this.state,
        ...{ keywordsPage, fotonotesPage, otherPage },
      }
    }
  }

  componentDidMount() {
    this.keywordsFetch()
    this.fotonotesFetch()
    this.otherFetch()
  }

  keywordsFetch = async () => {
    const httpClient = this.context
    const { keywordsPage, keywordsCount } = this.state
    let keywords = await httpClient.get(
      `/user/favorites/keywords?page=${keywordsPage}&count=${keywordsCount}`
    )

    this.setState({
      keywords: keywords.entities
        .map(k => k.idea)
        .map(i => {
          i.isFavorite = true
          return i
        }),
      keywordsTotal: keywords.pagination.count,
    })
  }

  keywordsPaginate = keywordsPage => {
    this.setState({ keywordsPage }, this.keywordsFetch)
  }

  fotonotesFetch = async () => {
    const httpClient = this.context
    const { fotonotesPage, fotonotesCount } = this.state
    let fotonotes = await httpClient.get(
      `/user/favorites/fotonotes?page=${fotonotesPage}&count=${fotonotesCount}`
    )

    this.setState({
      fotonotes: fotonotes.entities
        .map(f => f.idea)
        .map(i => {
          i.isFavorite = true
          return i
        }),
      fotonotesTotal: fotonotes.pagination.count,
    })
  }

  fotonotesPaginate = fotonotesPage => {
    this.setState({ fotonotesPage }, this.fotonotesFetch)
  }

  otherFetch = async () => {
    const httpClient = this.context
    const { otherPage, otherCount } = this.state
    let other = await httpClient.get(
      `/user/favorites/other?page=${otherPage}&count=${otherCount}`
    )

    this.setState({
      other: other.entities
        .map(o => o.idea)
        .map(i => {
          i.isFavorite = true
          return i
        }),
      otherTotal: other.pagination.count,
    })
  }

  otherPaginate = otherPage => {
    this.setState({ otherPage }, this.otherFetch)
  }

  render() {
    const { keywords, fotonotes, other } = this.state

    return (
      <div className="ceo-dashboard__container">
        <h2 className="notes__list-title">Favorisierte Ideen</h2>

        <Pagination
          paginate={this.keywordsPaginate}
          page={this.state.keywordsPage}
          count={this.state.keywordsCount}
          total={this.state.keywordsTotal}
        >
          <KeywordList
            keywords={keywords}
            linkParams={{
              keywordsPage: this.state.keywordsPage,
              fotonotesPage: this.state.fotonotesPage,
              otherPage: this.state.otherPage,
            }}
          />
        </Pagination>

        <Pagination
          paginate={this.fotonotesPaginate}
          page={this.state.fotonotesPage}
          count={this.state.fotonotesCount}
          total={this.state.fotonotesTotal}
        >
          <FotonotesList
            fotonotes={fotonotes}
            linkParams={{
              keywordsPage: this.state.keywordsPage,
              fotonotesPage: this.state.fotonotesPage,
              otherPage: this.state.otherPage,
            }}
          />
        </Pagination>

        <Pagination
          paginate={this.otherPaginate}
          page={this.state.otherPage}
          count={this.state.otherCount}
          total={this.state.otherTotal}
        >
          <IdeaList
            ideas={other}
            linkParams={{
              keywordsPage: this.state.keywordsPage,
              fotonotesPage: this.state.fotonotesPage,
              otherPage: this.state.otherPage,
            }}
          />
        </Pagination>
      </div>
    )
  }
}

export default CeoFavorites
