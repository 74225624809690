import React from 'react'
import LoaderIndicator from '../LoaderIndicator'
import Entry from './Entry'

export default props => {
  const { keywords, linkParams } = props

  return keywords.length > 0 ? (
    <ul className={`keyword-list keyword-list--spatial`}>
      {keywords.map(idea => (
        <Entry key={idea.id} idea={idea} linkParams={linkParams} />
      ))}
    </ul>
  ) : (
    <LoaderIndicator />
  )
}
