import React, { Component } from 'react'
import IdeaDetail from '../IdeaDetail'

class OptimisationIdeaDetail extends Component {
  render() {
    return (
      <IdeaDetail
        key="optimisation_idea"
        ideaType="optimisation"
        match={this.props.match}
      />
    )
  }
}
export default OptimisationIdeaDetail
