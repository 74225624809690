import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Popover from 'react-awesome-popover'
import TextareaAutosize from 'react-autosize-textarea'
import Rating from 'react-rating'
import { toast } from 'react-toastify'
import HttpClientContext from '../../utils/HttpClientContext'
import { NotificationContainer } from '..'
import { ReactComponent as InnovationIcon } from '../../styles/images/innovation-idea.svg'
import { ReactComponent as OptimisationIcon } from '../../styles/images/optimisation-idea.svg'
import { ReactComponent as ProductIcon } from '../../styles/images/product-idea.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'
import { ReactComponent as EditIcon } from '../../styles/images/pencil.svg'
import { ReactComponent as CameraIcon } from '../../styles/images/camera.svg'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { ReactComponent as MinusIcon } from '../../styles/images/minus.svg'
import { ReactComponent as CheckmarkIcon } from '../../styles/images/check-icon.svg'
import { ReactComponent as ReloadIcon } from '../../styles/images/reload.svg'
import { Pagination, Confirmation, LoaderIndicator } from '..'
import { ReactComponent as StarEmptyIcon } from '../../styles/images/star-empty.svg'
import { ReactComponent as StarFullIcon } from '../../styles/images/star-full.svg'
import { ReactComponent as CloseIcon } from '../../styles/images/close-new.svg'
import ImageUrlGenerator from '../../utils/ImageUrlGenerator'

function AddButton(props) {
  const { onClick } = props

  return (
    <button
      className="innovation-detail__add-button innovation-detail__add-button--pink"
      onClick={onClick}
    >
      <PlusIcon />
    </button>
  )
}

function ReloadButton(props) {
  const { onClick } = props

  return (
    <button className="innovation-detail__refresh-button" onClick={onClick}>
      <ReloadIcon />
    </button>
  )
}

function EditButton(props) {
  const { className, onClick } = props

  return (
    <button
      className={`innovation-detail__edit-button ${className}`}
      onClick={onClick}
    >
      <EditIcon />
    </button>
  )
}

function SaveButton(props) {
  const { className, handleSave } = props

  return (
    <button
      className={`innovation-detail__save-button innovation-detail__save-button--blue${
        className ? ' ' + className : ''
      }`}
      onClick={handleSave}
    >
      <ChevronIcon />
    </button>
  )
}

function UploadButton(props) {
  const { handleUpload } = props

  return (
    <label>
      <span className="innovation-detail__upload-button innovation-detail__upload-button--blue">
        <CameraIcon />
      </span>
      <input
        type="file"
        accept="image/*"
        name="optimisationImage"
        onChange={handleUpload}
      />
    </label>
  )
}

const defaultInputMode = {
  titleInput: false,
  descriptionInput: false,
  todoInput: false,
  linkInput: false,
  keywordInput: false,
}

const defaultConfirmationMode = {
  deleteIdea: false,
  overlay: false,
  data: {
    text: false,
    confirmAction: false,
  },
}

const defaultPopoverMode = {
  editTitle: false,
}

class InnovationIdea extends Component {
  TYPE_INNOVATION = 1
  TYPE_OPTIMISATION = 2
  TYPE_PRODUCT = 3

  types = {
    innovation: this.TYPE_INNOVATION,
    optimisation: this.TYPE_OPTIMISATION,
    product: this.TYPE_PRODUCT,
  }

  state = {
    idea: null,
    comments: [],
    commentsPage: 0,
    commentsCount: 3,
    pictures: [],
    picturesPage: 0,
    picturesCount: 2,
    departments: [],
    products: [],
    categories: [],
    editKeywords: false,
    isEditing: { ...defaultInputMode },
    confirmation: { ...defaultConfirmationMode },
    popover: { ...defaultPopoverMode },
    newTodo: '',
    newLink: '',
    newKeyword: '',
    showLoader: false,
  }

  constructor(props) {
    super(props)

    this.state = { ...this.state, ...props }
  }

  async componentDidMount() {
    await this.initData()
  }

  async initData() {
    this.showLoader()
    const httpClient = this.context
    const {
      match: {
        params: { id },
      },
    } = this.props

    let [departments, products, categories, idea] = await Promise.all([
      httpClient.get('/department/'),
      httpClient.get('/product/'),
      httpClient.get('/category/'),
      httpClient.get(`/${this.props.ideaType}_idea/${id}`),
    ])

    idea.headerpicture = false
    if (idea.pictures.length) {
      idea.headerpicture = idea.pictures.shift()
    }

    this.setState({
      idea,
      comments: idea.comments.entities,
      commentsTotal: idea.comments.pagination.count,
      picturesTotal: idea.pictures.length,
      departments,
      products,
      categories,
      editKeywords: false,
      isEditing: { ...defaultInputMode },
      confirmation: { ...defaultConfirmationMode },
      popover: { ...defaultPopoverMode },
      newTodo: '',
      newLink: '',
      newKeyword: '',
    })
    this.hideLoader()
    this.picturesFetch()
  }

  enterEditMode = (refName, callback = () => {}) => {
    this.setState(
      prevState => {
        return {
          isEditing: {
            ...prevState.isEditing,
            [refName]: true,
          },
        }
      },
      () => {
        callback()
        this[refName].focus()
      }
    )
  }

  enterConfirmationMode = (refName, text, confirmAction) => {
    this.setState(
      prevState => {
        return {
          confirmation: {
            ...prevState.confirmation,
            [refName]: true,
            data: {
              text,
              confirmAction,
            },
          },
        }
      },
      () => document.body.classList.add('overlay-open')
    )
  }

  togglePopoverMode = refName => {
    const status = !this.state.popover[refName]

    this.setState(prevState => {
      return {
        popover: {
          ...prevState.popover,
          [refName]: status,
        },
      }
    })
  }

  declineConfirmationMode = (callback = () => {}) =>
    this.setState({ confirmation: { ...defaultConfirmationMode } }, () => {
      document.body.classList.remove('overlay-open')
      callback()
    })

  handleIdeaEdit = event => {
    const {
      target: { name, value },
    } = event

    this.setState(prevState => {
      return {
        idea: {
          ...prevState.idea,
          [name]: value,
        },
      }
    })
  }

  handleSave = async () => {
    this.showLoader()
    const httpClient = this.context
    const { idea } = this.state

    await httpClient.post(
      `/${this.props.ideaType}_idea/${idea.id}/edit`,
      JSON.stringify({
        title: idea.title,
        description: idea.description,
        type: idea.type,
        confirmationPending: idea.confirmationPending,
      })
    )

    this.setState({
      isEditing: { ...defaultInputMode },
      popover: { ...defaultPopoverMode },
    })
    this.hideLoader()
  }

  handleDelete = async () => {
    const httpClient = this.context
    const { idea } = this.state

    const status = await httpClient.delete(
      `/${this.props.ideaType}_idea/${idea.id}`
    )
    this.declineConfirmationMode()

    if (status === 'Error') {
      toast.error(
        'Die Idee konnte nicht gelöscht werden, sie wurde favorisiert.'
      )
    } else {
      this.props.history.push(`/innovationroom/${this.props.ideaType}`)
    }

    return false
  }

  handleUpload = async event => {
    this.showLoader()
    event.preventDefault()

    const formData = new FormData()
    formData.append('file', event.target.files[0])

    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.post(
      `/${this.props.ideaType}_idea/${idea.id}/picture/new`,
      formData
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({ idea: updatedIdea })
      this.hideLoader()
    }
  }

  handlenNewListItemChange = event => {
    const {
      target: { name, value },
    } = event

    this.setState({ [name]: value })
  }

  saveTodo = async () => {
    this.showLoader()
    const httpClient = this.context
    const { idea, newTodo } = this.state

    if (newTodo == '') return

    const updatedIdea = await httpClient.post(
      `/${this.props.ideaType}_idea/${idea.id}/todo/new`,
      JSON.stringify({ todo: newTodo })
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({
        idea: updatedIdea,
        isEditing: { ...defaultInputMode },
        newTodo: '',
      })
      this.hideLoader()
    }
  }

  deleteTodo = async id => {
    this.showLoader()
    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.delete(
      `/${this.props.ideaType}_idea/${idea.id}/todo/${id}`
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({ idea: updatedIdea })
      this.hideLoader()
    }
  }

  toggleTodo = async id => {
    if (this.context.isUserCEO() && this.context.isCEOEnabled()) return

    this.showLoader()
    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.post(
      `/${this.props.ideaType}_idea/${idea.id}/todo/${id}/toggle`
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({ idea: updatedIdea })
      this.hideLoader()
    }
  }

  saveLink = async () => {
    this.showLoader()
    const httpClient = this.context
    const { idea, newLink } = this.state

    if (newLink == '') return

    const updatedIdea = await httpClient.post(
      `/${this.props.ideaType}_idea/${idea.id}/weblink/new`,
      JSON.stringify({ url: newLink })
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({
        idea: updatedIdea,
        isEditing: { ...defaultInputMode },
        newLink: '',
      })
      this.hideLoader()
    }
  }

  deleteLink = async id => {
    this.showLoader()
    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.delete(
      `/${this.props.ideaType}_idea/${idea.id}/weblink/${id}`
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({ idea: updatedIdea })
      this.hideLoader()
    }
  }

  saveKeyword = async () => {
    this.showLoader()
    const httpClient = this.context
    const { idea, newKeyword } = this.state

    if (newKeyword == '') return

    const updatedIdea = await httpClient.post(
      `/${this.props.ideaType}_idea/${idea.id}/keyword/new`,
      JSON.stringify({ keyword: newKeyword })
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({
        idea: updatedIdea,
        isEditing: { ...defaultInputMode },
        newKeyword: '',
      })
      this.hideLoader()
    }
  }

  deleteKeyword = async id => {
    this.showLoader()

    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.delete(
      `/${this.props.ideaType}_idea/${idea.id}/keyword/${id}`
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({ idea: updatedIdea })
      this.hideLoader()
    }
  }

  getIcon = () => {
    switch (this.types[this.props.ideaType]) {
      case this.TYPE_INNOVATION:
        return <InnovationIcon />
      case this.TYPE_OPTIMISATION:
        return <OptimisationIcon />
      case this.TYPE_PRODUCT:
        return <ProductIcon />
      default:
        console.log('unnown type')
    }
  }

  getType = () => {
    switch (this.types[this.props.ideaType]) {
      case this.TYPE_INNOVATION:
        return 'Innovationsidee'
      case this.TYPE_OPTIMISATION:
        return 'Optimierungsidee'
      case this.TYPE_PRODUCT:
        return 'Produktidee'
      default:
        console.log('unnown type')
    }
  }

  convert = async () => {
    const httpClient = this.context
    const { idea } = this.state
    await httpClient.get(
      `/${this.props.ideaType}_idea/${idea.id}/convert_to_product_idea`
    )

    this.props.history.push(`/innovationroom/product/detail/${idea.id}`)

    return false
  }

  enrichIdea = async () => {
    const httpClient = this.context
    const { idea } = this.state
    const updatedIdea = await httpClient.get(
      `/${this.props.ideaType}_idea/${idea.id}/enrich`
    )

    if (updatedIdea.redirected) {
      this.initData()
    } else {
      this.setState({ idea: updatedIdea })
      this.hideLoader()
    }
  }

  commentsFetch = async () => {
    const httpClient = this.context

    const { commentsPage, commentsCount, idea } = this.state
    let commentUrl = `/${this.props.ideaType}_idea/${idea.id}/latest_comments/`
    commentUrl += `?page=${commentsPage}`
    commentUrl += `&count=${commentsCount}`

    const comments = await httpClient.get(commentUrl)

    this.setState({
      comments: comments.entities,
      commentsTotal: comments.pagination.count,
    })
  }

  commentsPaginate = commentsPage => {
    this.setState({ commentsPage }, this.commentsFetch)
  }

  picturesFetch = async () => {
    const { picturesPage, picturesCount, idea } = this.state
    const offset = picturesPage * picturesCount

    const pictures = idea.pictures.slice(offset, offset + picturesCount)

    this.setState({
      pictures,
    })
  }

  picturesPaginate = picturesPage => {
    this.setState({ picturesPage }, this.picturesFetch)
  }

  deleteFotonote = async id => {
    this.showLoader()
    const httpClient = this.context

    const { idea } = this.state

    const result = await httpClient.delete(
      `/${this.props.ideaType}_idea/${idea.id}/picture/${id}`
    )

    if (result.redirected) {
      this.initData()
    } else {
      this.setState({ idea: result })
      this.hideLoader()
    }
  }

  getCategories = idea =>
    this.state.categories.filter(c => idea.categories.indexOf(c.id) > -1)

  getDepartment = idea =>
    this.state.departments.find(d => d.id === idea.department)

  getProduct = idea => this.state.products.find(p => p.id === idea.product)

  toggleFavorite = async () => {
    this.showLoader()

    const httpClient = this.context

    const { idea } = this.state
    const url = `/user/favorite/${idea.id}`

    idea.isFavorite ? await httpClient.delete(url) : await httpClient.post(url)
    idea.isFavorite = !idea.isFavorite

    this.setState({ idea })

    this.hideLoader()
  }

  showLoader = () => this.setState({ showLoader: true })
  hideLoader = () => this.setState({ showLoader: false })

  render() {
    const {
      idea,
      isEditing,
      newTodo,
      newLink,
      newKeyword,
      editKeywords,
      comments,
      pictures,
      showLoader,
      location,
    } = this.state

    if (!idea) {
      return <LoaderIndicator global={true} />
    }

    const categories = this.getCategories(idea)
    const department = this.getDepartment(idea)
    const product = this.getProduct(idea)

    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const inFavorite = location.pathname.includes('/favorites')

    return (
      <Fragment>
        <Link
          to={{
            pathname: `${
              isCeo
                ? `/ceo${
                    inFavorite
                      ? '/favorites'
                      : `/innovationroom/${this.props.ideaType}`
                  }`
                : `/innovationroom/${this.props.ideaType}`
            }`,
            linkParams: location.linkParams || {},
          }}
          className="innovation-detail__back-button innovation-detail__back-button--blue"
        >
          <CloseIcon />
        </Link>
        {showLoader && <LoaderIndicator global={true} />}
        <div className="innovation-detail__detail-header">
          <span className="innovation-detail__detail-header-icon">
            {this.getIcon()}
          </span>
          <div className="innovation-detail__subheader-container hyphenate">
            <span className="innovation-detail__detail-subheader">
              {this.getType()}
            </span>
            {isEditing.titleInput && !isCeo ? (
              <TextareaAutosize
                name="title"
                innerRef={el => (this.titleInput = el)}
                value={idea.title}
                onChange={this.handleIdeaEdit}
                className="innovation-detail__input innovation-detail__input--title"
              />
            ) : (
              idea.title
            )}
          </div>

          {isCeo ? (
            <button
              type="button"
              className={`ceo-favorite-button${
                idea.isFavorite ? ' ceo-favorite-button-active' : ''
              }`}
              onClick={() => this.toggleFavorite()}
            >
              {idea.isFavorite ? <StarFullIcon /> : <StarEmptyIcon />}
            </button>
          ) : isEditing.titleInput ? (
            <SaveButton handleSave={this.handleSave} />
          ) : (
            <Popover
              placement="left-start"
              arrow={false}
              action={null}
              open={this.state.popover.editTitle}
              contentClass="innovation-detail__popover-content innovation-detail__popover-content--large"
            >
              <EditButton
                className="innovation-detail__edit-button--blue"
                onClick={() => this.togglePopoverMode('editTitle')}
              />
              <div>
                <div className="innovation-detail__popover-button-group">
                  <button
                    type="button"
                    onClick={() => this.enterEditMode('titleInput')}
                  >
                    Umbenennen
                  </button>
                  {/*<button type="button">Elemente anordnen</button>*/}
                  {/*<button type="button">Eintrag archivieren</button>*/}
                  <button
                    type="button"
                    className="innovation-detail__popover-button innovation-detail__popover-button--warning"
                    onClick={() => this.enterConfirmationMode('deleteIdea')}
                  >
                    Eintrag aus Dipia löschen
                  </button>
                </div>
                {this.state.confirmation.deleteIdea && (
                  <div className="innovation-detail__popover-confirmation">
                    <Confirmation
                      fullsize
                      inline
                      text={'WIRKLICH LÖSCHEN?'}
                      confirmAction={() => this.handleDelete()}
                      declineAction={() =>
                        this.declineConfirmationMode(() =>
                          this.togglePopoverMode('editTitle')
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </Popover>
          )}
        </div>

        <div className="innovation-detail__container">
          <div className="innovation-detail__container innovation-detail__container--detail">
            {idea && idea.headerpicture.file && (
              <section className="innovation-detail__detail-section">
                <div
                  key={idea.headerpicture.id}
                  className="innovation-detail__fotonote"
                >
                  <img
                    className="innovation-detail__detail-image"
                    src={`/uploads/images/${ImageUrlGenerator(
                      idea.headerpicture.file,
                      idea.organisationSlug
                    )}`}
                    alt={`${idea.title} Foto 0`}
                  />
                  {!isCeo && (
                    <Popover
                      placement="left-start"
                      arrow={false}
                      action={null}
                      open={this.state.popover['editFotonoteHeader']}
                      contentClass="innovation-detail__popover-content innovation-detail__popover-content--large"
                    >
                      <EditButton
                        className="innovation-detail__edit-button--blue"
                        onClick={() =>
                          this.togglePopoverMode('editFotonoteHeader')
                        }
                      />
                      <div>
                        <div className="innovation-detail__popover-button-group">
                          <button
                            type="button"
                            className="innovation-detail__popover-button innovation-detail__popover-button--warning"
                            onClick={() =>
                              this.deleteFotonote(idea.headerpicture.id)
                            }
                          >
                            Löschen
                          </button>
                        </div>
                      </div>
                    </Popover>
                  )}
                </div>
              </section>
            )}

            <section
              key="description"
              className="innovation-detail__detail-section"
            >
              <div className="innovation-detail__section-title">
                Kurzbeschreibung
                <div className="innovation-detail__section-actions">
                  {isCeo ? null : isEditing.descriptionInput ? (
                    <SaveButton handleSave={this.handleSave} />
                  ) : (
                    <EditButton
                      className="innovation-detail__edit-button--blue"
                      onClick={() => this.enterEditMode('descriptionInput')}
                    />
                  )}
                </div>
              </div>

              <p className="innovation-detail__detail-date">
                {new Date(idea.createDate.date).toLocaleDateString()}
              </p>

              <p className="innovation-detail__detail-description hyphenate">
                {isEditing.descriptionInput && !isCeo ? (
                  <TextareaAutosize
                    name="description"
                    innerRef={el => (this.descriptionInput = el)}
                    value={idea.description}
                    onChange={this.handleIdeaEdit}
                  />
                ) : (
                  idea.description
                )}
              </p>
            </section>

            {idea.type === this.TYPE_OPTIMISATION && [
              <section
                key="importance"
                className="innovation-detail__detail-section"
              >
                <div className="innovation-detail__section-title">
                  Dringlichkeit
                </div>
                {!idea.rating || !idea.rating.main ? (
                  <p>noch keine Bewertungen von anderen NutzerInnen</p>
                ) : (
                  <div className="innovation-detail__rating">
                    <Rating
                      emptySymbol={<StarEmptyIcon />}
                      fullSymbol={<StarFullIcon />}
                      initialRating={idea.rating.main}
                      readonly
                    />
                    <dl>
                      {idea.rating.subs.map(sub => [
                        <dt key={`rating${sub.id}_term`}>
                          {sub.count} Bewertungen in:
                        </dt>,
                        <dd key={`rating${sub.id}_desc`}>{sub.dep_name}</dd>,
                      ])}
                    </dl>
                  </div>
                )}
              </section>,
              <section
                key="classification"
                className="innovation-detail__detail-section"
              >
                <div className="innovation-detail__section-title">
                  Einordnung
                </div>
                <dl>
                  {categories.length > 0 && [
                    <dt key="category_term">Kategorie:</dt>,
                    <dd key="category_desc">
                      {categories.map(category => category.title).join(', ')}
                    </dd>,
                  ]}
                  {department && [
                    <dt key="department_term">Abteilung:</dt>,
                    <dd key="department_desc">{department.name}</dd>,
                  ]}
                  {product && [
                    <dt key="product_term">Produkt:</dt>,
                    <dd key="product_desc">{product.title}</dd>,
                  ]}
                  {idea.location && [
                    <dt key="location_term">Ort:</dt>,
                    <dd key="location_desc">{idea.location}</dd>,
                  ]}
                </dl>
              </section>,
              <section
                key="solutions"
                className="innovation-detail__detail-section"
              >
                <div className="innovation-detail__section-title">
                  Kommentare & Lösungsvorschläge
                </div>
                {!comments.length ? (
                  <p>Noch keine Kommentare von anderen NutzerInnen</p>
                ) : (
                  <div key="comments">
                    <Pagination
                      key="comments_pagination"
                      paginate={this.commentsPaginate}
                      page={this.state.commentsPage}
                      count={this.state.commentsCount}
                      total={this.state.commentsTotal}
                    >
                      {comments.map(comment => (
                        <div
                          key={comment.id}
                          className="innovation-detail__comment"
                        >
                          {comment.comment}
                        </div>
                      ))}
                    </Pagination>
                  </div>
                )}
              </section>,
            ]}

            <section className="innovation-detail__detail-section">
              <div className="innovation-detail__section-title">
                Eigene Fotos
                {!isCeo && (
                  <div className="innovation-detail__section-actions">
                    <UploadButton handleUpload={this.handleUpload} />
                  </div>
                )}
              </div>

              <Pagination
                key="pictures_pagination"
                paginate={this.picturesPaginate}
                page={this.state.picturesPage}
                count={this.state.picturesCount}
                total={this.state.picturesTotal}
              >
                {pictures.map((pic, index) => (
                  <div key={pic.id} className="innovation-detail__fotonote">
                    <img
                      className="innovation-detail__detail-image"
                      src={`/uploads/images/${ImageUrlGenerator(
                        pic.file,
                        idea.organisationSlug
                      )}`}
                      alt={`${idea.title} Foto ${index}`}
                    />
                    {!isCeo && (
                      <Popover
                        placement="left-start"
                        arrow={false}
                        action={null}
                        open={this.state.popover[`editFotonote${pic.id}`]}
                        contentClass="innovation-detail__popover-content innovation-detail__popover-content--large"
                      >
                        <EditButton
                          className="innovation-detail__edit-button--blue"
                          onClick={() =>
                            this.togglePopoverMode(`editFotonote${pic.id}`)
                          }
                        />
                        <div>
                          <div className="innovation-detail__popover-button-group">
                            <button
                              type="button"
                              className="innovation-detail__popover-button innovation-detail__popover-button--warning"
                              onClick={() => this.deleteFotonote(pic.id)}
                            >
                              Löschen
                            </button>
                          </div>
                        </div>
                      </Popover>
                    )}
                  </div>
                ))}
              </Pagination>
            </section>

            <section className="innovation-detail__detail-section">
              <div className="innovation-detail__section-title">
                Verwandte Begriffe
                {!isCeo && (
                  <div className="innovation-detail__section-actions">
                    <AddButton
                      onClick={() =>
                        this.enterEditMode('keywordInput', () =>
                          this.setState({ editKeywords: false })
                        )
                      }
                    />
                    <EditButton
                      className="innovation-detail__edit-button--blue"
                      onClick={() =>
                        this.setState({
                          editKeywords: true,
                          isEditing: { ...defaultInputMode },
                        })
                      }
                    />
                  </div>
                )}
              </div>

              <ul className={`keyword-list keyword-list--spatial`}>
                {idea.keywords.map(keyword => (
                  <li key={keyword.id} className="keyword-list-item">
                    <button
                      type="button"
                      onClick={() =>
                        this.enterConfirmationMode(
                          'overlay',
                          `WEBSUCHE NACH "${keyword.keyword}"?`,
                          () => {
                            window.open(
                              `https://www.google.com/search?q=${
                                keyword.keyword
                              }`,
                              '_blank'
                            )
                            this.declineConfirmationMode()
                          }
                        )
                      }
                    >
                      {keyword.keyword}
                    </button>
                    {editKeywords && !isCeo && (
                      <button
                        key={keyword.id}
                        type="button"
                        className="keyword-list-item-remove"
                        onClick={() =>
                          this.enterConfirmationMode(
                            'overlay',
                            `EINTRAG "${keyword.keyword}" LÖSCHEN?`,
                            () => {
                              this.deleteKeyword(keyword.id)
                              this.declineConfirmationMode()
                            }
                          )
                        }
                      >
                        <MinusIcon />
                      </button>
                    )}
                  </li>
                ))}
                {isEditing.keywordInput && !isCeo && (
                  <li className="keyword-list-item keyword-list-item-new">
                    <input
                      type="text"
                      name="newKeyword"
                      ref={el => (this.keywordInput = el)}
                      value={newKeyword}
                      onChange={this.handlenNewListItemChange}
                      placeholder="Begriff..."
                    />

                    <SaveButton
                      handleSave={this.saveKeyword}
                      className="save-button"
                    />
                  </li>
                )}
              </ul>
            </section>

            <section className="innovation-detail__detail-section">
              <div className="innovation-detail__section-title">
                Notizen & To Do
                {!isCeo && (
                  <div className="innovation-detail__section-actions">
                    <AddButton
                      onClick={() => this.enterEditMode('todoInput')}
                    />
                  </div>
                )}
              </div>

              <ul className="todo-list">
                {isEditing.todoInput && !isCeo && (
                  <li className="todo-list-item">
                    <input
                      type="text"
                      name="newTodo"
                      ref={el => (this.todoInput = el)}
                      value={newTodo}
                      onChange={this.handlenNewListItemChange}
                      placeholder="Todo..."
                    />

                    <SaveButton handleSave={this.saveTodo} />
                  </li>
                )}

                {idea.todos.map(todo => (
                  <li key={todo.id} className="todo-list-item">
                    {todo.todo}

                    {!isCeo && (
                      <Popover
                        placement="left-start"
                        arrow={false}
                        contentClass="innovation-detail__popover-content innovation-detail__popover-content--small"
                      >
                        <EditButton className="innovation-detail__edit-button--blue" />
                        <div>
                          <div className="innovation-detail__popover-button-group">
                            <button
                              type="button"
                              onClick={() => this.deleteTodo(todo.id)}
                            >
                              Löschen
                            </button>
                          </div>
                        </div>
                      </Popover>
                    )}
                    <button
                      type="button"
                      onClick={() => this.toggleTodo(todo.id)}
                      className={`todo-list__checkmark-button ${
                        todo.done ? 'todo-list__checkmark-button--done' : ''
                      }`}
                    >
                      <CheckmarkIcon />
                    </button>
                  </li>
                ))}
              </ul>
            </section>

            <section className="innovation-detail__detail-section">
              <div className="innovation-detail__section-title">
                Eigene Links
                {!isCeo && (
                  <div className="innovation-detail__section-actions">
                    <AddButton
                      onClick={() => this.enterEditMode('linkInput')}
                    />
                  </div>
                )}
              </div>

              <ul className="link-list">
                {isEditing.linkInput && !isCeo && (
                  <li className="link-list-item">
                    http(s)://
                    <input
                      type="text"
                      name="newLink"
                      ref={el => (this.linkInput = el)}
                      value={newLink}
                      onChange={this.handlenNewListItemChange}
                      placeholder="Link..."
                    />
                    <SaveButton handleSave={this.saveLink} />
                  </li>
                )}

                {idea.userLinks.map(link => (
                  <li key={link.id} className="link-list-item">
                    <a
                      href={`http://${link.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.url}
                    </a>

                    {!isCeo && (
                      <Popover
                        placement="left-start"
                        arrow={false}
                        contentClass="innovation-detail__popover-content innovation-detail__popover-content--small"
                      >
                        <EditButton className="innovation-detail__edit-button--blue" />
                        <div>
                          <div className="innovation-detail__popover-button-group">
                            <button
                              type="button"
                              onClick={() => this.deleteLink(link.id)}
                            >
                              Löschen
                            </button>
                          </div>
                        </div>
                      </Popover>
                    )}
                  </li>
                ))}
              </ul>
            </section>

            <section className="innovation-detail__detail-section">
              <span className="innovation-detail__section-title">
                Automatische Websuche
                {!isCeo && (
                  <div className="innovation-detail__section-actions">
                    <ReloadButton onClick={() => this.enrichIdea()} />
                  </div>
                )}
              </span>

              <ul className="link-list">
                {idea.webLinks.map(link => (
                  <li key={link.id} className="link-list-item">
                    <button
                      type="button"
                      onClick={() =>
                        this.enterConfirmationMode(
                          'overlay',
                          `SEITE IM BROWSER ÖFFNEN?`,
                          () => {
                            window.open(link.url, '_blank')
                            this.declineConfirmationMode()
                          }
                        )
                      }
                    >
                      {link.url}
                    </button>
                  </li>
                ))}
              </ul>
            </section>

            {idea.type !== this.TYPE_PRODUCT && !isCeo && (
              <button
                type="button"
                className="innovation-detail__button"
                onClick={() => this.convert()}
              >
                {this.getType()} in Produktidee umwandeln
                <span>
                  <ChevronIcon />
                </span>
              </button>
            )}
          </div>
        </div>

        {this.state.confirmation.overlay && (
          <Confirmation
            text={this.state.confirmation.data.text}
            confirmAction={this.state.confirmation.data.confirmAction}
            declineAction={() => this.declineConfirmationMode()}
          />
        )}

        <NotificationContainer />
      </Fragment>
    )
  }
}
export default withRouter(InnovationIdea)

InnovationIdea.contextType = HttpClientContext
