import React, { Component } from 'react'
import IdeaDetail from '../IdeaDetail'

class ProductIdeaDetail extends Component {
  render() {
    return (
      <IdeaDetail
        key="product_idea"
        ideaType="product"
        match={this.props.match}
      />
    )
  }
}
export default ProductIdeaDetail
