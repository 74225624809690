import React, { Component, Fragment } from 'react'
import Carousel from 'nuka-carousel'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import OptimisationIdeaRating from './OptimisationIdeaRating'
import HttpClientContext from '../../utils/HttpClientContext'
import { NotificationContainer } from '..'
import { ReactComponent as OptimisationIcon } from '../../styles/images/optimisation-idea.svg'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron.svg'
import { Pagination, CeoToolbar, IdeaList } from '..'
import LoaderIndicator from '../LoaderIndicator'

class InnovationRoomOptimisation extends Component {
  static contextType = HttpClientContext

  state = {
    unratedOptimisationIdeas: null,
    latestOptimisationIdeas: [],
    latestPage: 0,
    latestCount: 8,
    departmentFilter: '',
    departments: null,
    products: null,
    categories: null,
  }

  constructor(props) {
    super(props)

    const { location } = props

    if (location.linkParams) {
      const { latestPage } = location.linkParams
      this.state = { ...this.state, ...{ latestPage } }
    }
  }

  componentDidMount() {
    this.fetchLatest()
    this.fetchUnrated()
    this.fetchData()
  }

  fetchUnrated = async () => {
    const httpClient = this.context

    const unratedOptimisationIdeas = await httpClient.get(
      '/optimisation_idea/unrated'
    )

    this.setState({
      unratedOptimisationIdeas,
    })
  }

  fetchLatest = async () => {
    const httpClient = this.context

    const { latestPage, latestCount, departmentFilter } = this.state
    let fetchUrl = '/optimisation_idea/latest'
    fetchUrl += `?page=${latestPage}`
    fetchUrl += `&count=${latestCount}`

    if (this.context.isUserCEO() && departmentFilter) {
      fetchUrl += `&department=${departmentFilter}`
    }

    const latestOptimisationIdeas = await httpClient.get(fetchUrl)

    this.setState({
      latestOptimisationIdeas: latestOptimisationIdeas.entities,
      latestTotal: latestOptimisationIdeas.pagination.count,
    })
  }

  fetchData = async () => {
    const httpClient = this.context

    let [departments, products, categories] = await Promise.all([
      httpClient.get('/department/'),
      httpClient.get('/product/'),
      httpClient.get('/category/'),
    ])

    this.setState({
      departments,
      products,
      categories,
    })
  }

  paginateLatest = latestPage => {
    this.setState({ latestPage }, this.fetchLatest)
  }

  filterByDepartment = departmentFilter => {
    this.setState({ departmentFilter }, this.fetchLatest)
  }

  handleOptimisationIdeaRating = async ({ id, value, comment }) => {
    const url = `/optimisation_idea/${id}/rating`
    const data = { rating: value, comment }

    const httpClient = this.context
    await httpClient.post(url, JSON.stringify(data))
    toast.success('Danke für Ihre Bewertung!')

    const { unratedOptimisationIdeas } = this.state
    const indexOfElementToRemove = unratedOptimisationIdeas.findIndex(
      idea => idea.id == id
    )

    unratedOptimisationIdeas.splice(indexOfElementToRemove, 1)
    this.setState({ unratedOptimisationIdeas })
  }

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()

    return (
      <Fragment>
        <section className="innovationRoom__section">
          <h2 className="innovationRoom__title">Optimierungsideen</h2>
          <div className="innovationRoom__new-idea">
            <OptimisationIcon />
            {!isCeo && (
              <Link
                to="/innovationroom/optimisation/new"
                className="innovationRoom__new-idea-button"
              >
                <span>Neue Optimierungsidee</span>
                <span className="innovationRoom__new-idea-icon">
                  <PlusIcon />
                </span>
              </Link>
            )}
          </div>
          {isCeo && (
            <CeoToolbar
              filterByDepartment={this.filterByDepartment}
              currentDepFilter={this.state.departmentFilter}
            />
          )}

          {this.state.latestOptimisationIdeas && (
            <Pagination
              paginate={this.paginateLatest}
              page={this.state.latestPage}
              count={this.state.latestCount}
              total={this.state.latestTotal}
            >
              {/*<button type="button" className="innovationRoom__sorting">
                Nach Datum
              </button>*/}
              <IdeaList
                key="optimisation"
                ideas={this.state.latestOptimisationIdeas}
                linkParams={{
                  latestPage: this.state.latestPage,
                }}
              />
            </Pagination>
          )}
        </section>

        <LoaderIndicator
          loaded={Array.isArray(this.state.unratedOptimisationIdeas)}
        >
          {Array.isArray(this.state.unratedOptimisationIdeas) &&
            this.state.unratedOptimisationIdeas.length > 0 &&
            this.state.categories !== null &&
            this.state.products !== null &&
            this.state.departments !== null && (
              <section className="innovationRoom__rating">
                <h2 className="innovationRoom__rating-heading">
                  Optimierungsideen Ihrer Kolleg:innen
                </h2>

                <Carousel
                  transitionMode="fade"
                  heightMode="current"
                  wrapAround={true}
                  dragging={false}
                  swiping={false}
                  width={'auto'}
                  renderTopLeftControls={({ previousSlide }) => (
                    <button
                      className="innovationRoom__rating-control innovationRoom__rating-control--prev"
                      onClick={previousSlide}
                    >
                      <ChevronIcon />
                    </button>
                  )}
                  renderTopRightControls={({ nextSlide }) => (
                    <button
                      className="innovationRoom__rating-control innovationRoom__rating-control--next"
                      onClick={nextSlide}
                    >
                      <ChevronIcon />
                    </button>
                  )}
                  renderCenterLeftControls={null}
                  renderCenterRightControls={null}
                  renderBottomCenterControls={() => null}
                  className="innovationRoom__carousel"
                >
                  {this.state.unratedOptimisationIdeas.map(idea => (
                    <OptimisationIdeaRating
                      key={idea.id}
                      idea={idea}
                      handleRating={this.handleOptimisationIdeaRating}
                      departments={this.state.departments}
                      products={this.state.products}
                      categories={this.state.categories}
                    />
                  ))}
                </Carousel>
              </section>
            )}
        </LoaderIndicator>

        <NotificationContainer />
      </Fragment>
    )
  }
}

export default InnovationRoomOptimisation
