import USER_ROLES from './UserRoles'

const LOGOUT_STATUS_CODES = [401, 403]
const REDIRECT_STATUS_CODES = [301, 302, 303, 307, 308]
const CEO_ROUTE = '/ceo'

export default class HttpClient {
  constructor(newBasePath = '/api') {
    this.basePath = newBasePath
    this.apiToken = localStorage.getItem('apiToken') || ''
    if (this.apiToken) {
      this.setUser(HttpClient.getTokenData(this.apiToken))
    } else {
      this.user = {}
    }
  }

  fetchAndRedirect = async (method, url, body) => {
    const basePath = this.isCEOEnabled()
      ? this.basePath + CEO_ROUTE
      : this.basePath
    const headers = this.createRequestHeaders(body)
    let requestUrl = basePath + url

    let response

    do {
      if (response && response.headers.has('location')) {
        requestUrl = response.headers.get('location')
      }

      response = await fetch(requestUrl, {
        method,
        headers,
        body,
        credentials: 'include',
        redirect: 'manual',
      })

      if (response.type === 'opaqueredirect') {
        return { redirected: true }
      }
    } while (REDIRECT_STATUS_CODES.includes(response.status))

    if (response.status != 200) {
      if (LOGOUT_STATUS_CODES.includes(response.status)) {
        this.logoutUser()
      } else {
        return {}
      }
    }

    return await response.json()
  }

  getUser = () => this.user

  isUserAuthenticated = () => !!this.apiToken

  hasRole = role => HttpClient.getTokenData(this.apiToken).roles.includes(role)

  isGranted = roles => {
    const userRoles = HttpClient.getTokenData(this.apiToken).roles
    return roles
      .map(role => userRoles.includes(role))
      .reduce((prev, current) => prev || current, false)
  }

  isUserCEO = () => this.hasRole(USER_ROLES.CEO)
  isCEOEnabled = () => !window.location.pathname.indexOf('/ceo')

  isAdmin = () => this.isGranted([USER_ROLES.APP_ADMIN, USER_ROLES.ORG_ADMIN])
  isAppAdmin = () => this.isGranted([USER_ROLES.APP_ADMIN])
  isOrgAdmin = () => this.isGranted([USER_ROLES.ORG_ADMIN])

  loginUser = ({ username, password }) => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch('/login_check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })

      if (response.status != 200) {
        return reject()
      }

      const { token, name, roles } = await response.json()
      this.saveUserInfo({ token, name, roles })

      resolve()
    })
  }

  setUser = userInfo => {
    const name = userInfo.firstName
    const username = userInfo.username
    const organisation = userInfo.organisation

    const isAppAdmin = this.hasRole(USER_ROLES.APP_ADMIN)
    const isOrgAdmin = this.hasRole(USER_ROLES.ORG_ADMIN)

    this.user = {
      name,
      username,
      isAppAdmin,
      isOrgAdmin,
      organisation,
    }
  }

  saveUserInfo = ({ token }) => {
    const userInfo = HttpClient.getTokenData(token)
    this.apiToken = token
    this.setUser(userInfo)

    localStorage.setItem('apiToken', this.apiToken)
  }

  logoutUser = () => {
    this.apiToken = ''
    this.user = {}

    localStorage.removeItem('apiToken')
    localStorage.removeItem('ceoEnabled')

    document.location = '/'
  }

  createRequestHeaders = body => {
    let headers = new Headers()
    headers.set('Authorization', `Bearer ${this.apiToken}`)
    if (body instanceof FormData) {
      // Do not set content-type when formData is body
      // headers.set('Content-Type', `multipart/form-data`)
    } else {
      headers.set('Content-Type', `application/json`)
    }

    return headers
  }

  static getTokenData = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return {
        username: '',
        name: '',
        firstName: '',
        lastName: '',
        roles: [],
      }
    }
  }

  get = url =>
    new Promise(async resolve =>
      resolve(await this.fetchAndRedirect('GET', url))
    )
  post = (url, body) =>
    new Promise(async resolve =>
      resolve(await this.fetchAndRedirect('POST', url, body))
    )
  delete = url =>
    new Promise(async resolve =>
      resolve(await this.fetchAndRedirect('DELETE', url))
    )
}
