import React from 'react'
import { ReactComponent as InnovationIdeaIcon } from '../styles/images/innovation-idea.svg'
import { ReactComponent as OptimisationIdeaIcon } from '../styles/images/optimisation-idea.svg'
import { ReactComponent as ProductIdeaIcon } from '../styles/images/product-idea.svg'
import { ReactComponent as FotonoteIdeaIcon } from '../styles/images/camera.svg'

class IdeaTypes {
  static INNOVATION = 1
  static OPTIMISATION = 2
  static PRODUCT = 3
  static FOTO_NOTE = 4

  static typeIcon = (type, className) => {
    switch (type) {
      case IdeaTypes.OPTIMISATION:
        return <OptimisationIdeaIcon className={className} />
      case IdeaTypes.PRODUCT:
        return <ProductIdeaIcon className={className} />
      case IdeaTypes.FOTO_NOTE:
        return <FotonoteIdeaIcon className={className} />
      default:
      case IdeaTypes.INNOVATION:
        return <InnovationIdeaIcon className={className} />
    }
  }

  static fromType = type => {
    switch (type) {
      case IdeaTypes.INNOVATION:
        return 'Innovationsidee'
      case IdeaTypes.OPTIMISATION:
        return 'Optimierungsidee'
      case IdeaTypes.PRODUCT:
        return 'Produktidee'
      case IdeaTypes.FOTO_NOTE:
        return 'Fotonotiz'
      default:
        return 'Idee'
    }
  }

  static fromIdea = idea => {
    return IdeaTypes.fromType(idea.type)
  }

  static isInnovation = idea =>
    !idea.confirmationPending && idea.type === IdeaTypes.INNOVATION
  static isOptimisation = idea =>
    !idea.confirmationPending && idea.type === IdeaTypes.OPTIMISATION
  static isProduct = idea =>
    !idea.confirmationPending && idea.type === IdeaTypes.PRODUCT
  static isFotonote = idea =>
    idea.confirmationPending && idea.type === IdeaTypes.FOTO_NOTE
  static isKeyword = idea =>
    idea.confirmationPending && IdeaTypes.FOTO_NOTE !== IdeaTypes.fromIdea(idea)

  static apiRouteFromType = type => {
    switch (type) {
      case IdeaTypes.INNOVATION:
        return 'innovation_idea'
      case IdeaTypes.OPTIMISATION:
        return 'optimisation_idea'
      case IdeaTypes.PRODUCT:
        return 'product_idea'
      case IdeaTypes.FOTO_NOTE:
        return 'fotonote_idea'
      default:
        console.log('unknown type')
    }
  }

  static apiRouteFromIdea = idea => IdeaTypes.apiRouteFromType(idea.type)

  static appRouteFromType = type => {
    switch (type) {
      case IdeaTypes.INNOVATION:
        return '/innovation'
      case IdeaTypes.OPTIMISATION:
        return '/optimisation'
      case IdeaTypes.PRODUCT:
        return '/product'
      case IdeaTypes.FOTO_NOTE:
        return '/notes/fotonote'
      default:
        console.log('unknown type')
    }
  }

  static appRouteFromIdea = idea =>
    IdeaTypes.isKeyword(idea) || IdeaTypes.appRouteFromType(idea.type)

  static detailsRoute = idea => {
    if (IdeaTypes.isKeyword(idea)) {
      return `/notes/keyword/${idea.id}/confirm`
    }

    if (IdeaTypes.isFotonote(idea)) {
      return `/notes/fotonote/${idea.id}/confirm`
    }

    return `/innovationroom${IdeaTypes.appRouteFromIdea(idea)}/detail/${
      idea.id
    }`
  }
}

export { IdeaTypes }
