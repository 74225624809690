import React, { Component, Fragment } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import {
  KeywordList,
  Pagination,
  CeoToolbar,
  FotonotesList,
  LoaderIndicator,
} from '..'

const typeToSortCriteria = {
  spatial: 'createDate',
  listByDate: 'createDate',
  listByAlphabet: 'title',
}

const typeToSortOrder = {
  spatial: 'desc',
  listByDate: 'desc',
  listByAlphabet: 'asc',
}

class Notes extends Component {
  static contextType = HttpClientContext

  state = {
    displayType: 'listByDate',
    keywords: null,
    keywordsCount: 12,
    keywordsPage: 0,
    keywordsWithinLastWeek: null,
    keywordsWeekCount: 12,
    keywordsWeekPage: 0,
    keywordsWithinLastYear: null,
    keywordsYearCount: 12,
    keywordsYearPage: 0,
    fotonotes: null,
    fotonotesCount: 9,
    fotonotesPage: 0,
    departmentFilter: '',
  }

  loaderHelper = null

  constructor(props) {
    super(props)

    const { location } = props

    if (location.linkParams) {
      const { keywordsPage, fotonotesPage } = location.linkParams
      this.state = { ...this.state, ...{ keywordsPage, fotonotesPage } }
    }
  }

  componentDidMount() {
    this.keywordsFetch()
    //this.keywordsWeekFetch()
    //this.keywordsYearFetch()
    this.fotonotesFetch()
  }

  fetch = async url => {
    const httpClient = this.context

    const { departmentFilter } = this.state

    if (this.context.isUserCEO() && departmentFilter) {
      url += `&department=${departmentFilter}`
    }

    return httpClient.get(url)
  }

  keywordsFetch = async () => {
    const { displayType, keywordsPage, keywordsCount } = this.state
    let keywordUrl = '/innovation_idea/keywords'
    keywordUrl += `?orderBy=${typeToSortCriteria[displayType]}`
    keywordUrl += `&order=${typeToSortOrder[displayType]}`
    keywordUrl += `&page=${keywordsPage}`
    keywordUrl += `&count=${keywordsCount}`

    const keywords = await this.fetch(keywordUrl)

    this.setState({
      keywords: keywords.entities,
      keywordsTotal: keywords.pagination.count,
    })
  }

  keywordsPaginate = keywordsPage => {
    this.setState({ keywordsPage }, this.keywordsFetch)
  }

  keywordsWeekFetch = async () => {
    const { displayType, keywordsWeekPage, keywordsWeekCount } = this.state
    let keywordUrl = '/innovation_idea/keywords/week'
    keywordUrl += `?orderBy=${typeToSortCriteria[displayType]}`
    keywordUrl += `&order=${typeToSortOrder[displayType]}`
    keywordUrl += `&page=${keywordsWeekPage}`
    keywordUrl += `&count=${keywordsWeekCount}`

    const keywords = await this.fetch(keywordUrl)

    this.setState({
      keywordsWithinLastWeek: keywords.entities,
      keywordsWeekTotal: keywords.pagination.count,
    })
  }

  keywordsWeekPaginate = keywordsWeekPage => {
    this.setState({ keywordsWeekPage }, this.keywordsWeekFetch)
  }

  fotonotesFetch = async () => {
    const { fotonotesPage, fotonotesCount } = this.state
    let fotonoteUrl = '/fotonote_idea/'
    fotonoteUrl += `?page=${fotonotesPage}`
    fotonoteUrl += `&count=${fotonotesCount}`

    const fotonotes = await this.fetch(fotonoteUrl)

    this.setState({
      fotonotes: fotonotes.entities,
      fotonotesTotal: fotonotes.pagination.count,
    })
  }

  fotonotesPaginate = fotonotesPage => {
    this.setState({ fotonotesPage }, this.fotonotesFetch)
  }

  keywordsYearFetch = async () => {
    const { displayType, keywordsYearPage, keywordsYearCount } = this.state
    let keywordUrl = '/innovation_idea/keywords/year'
    keywordUrl += `?orderBy=${typeToSortCriteria[displayType]}`
    keywordUrl += `&order=${typeToSortOrder[displayType]}`
    keywordUrl += `&page=${keywordsYearPage}`
    keywordUrl += `&count=${keywordsYearCount}`

    const keywords = await this.fetch(keywordUrl)

    this.setState({
      keywordsWithinLastYear: keywords.entities,
      keywordsYearTotal: keywords.pagination.count,
    })
  }

  keywordsYearPaginate = keywordsYearPage => {
    this.setState({ keywordsYearPage }, this.keywordsYearFetch)
  }

  handleDisplayTypeChange = event => {
    this.setState({ displayType: event.target.value }, this.keywordsFetch)
  }

  filterByDepartment = departmentFilter => {
    this.setState({ departmentFilter }, () => {
      this.keywordsFetch()
      this.fotonotesFetch()
    })
  }

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()

    return (
      <Fragment>
        <section className="notes__section">
          <h2 className="notes__list-title">Innovationsstichworte</h2>

          {isCeo && (
            <CeoToolbar
              filterByDepartment={this.filterByDepartment}
              currentDepFilter={this.state.departmentFilter}
            />
          )}

          <LoaderIndicator loaded={Array.isArray(this.state.keywords)}>
            {Array.isArray(this.state.keywords) &&
              this.state.keywords.length > 0 && (
                <div className="notes__list-container">
                  <Pagination
                    paginate={this.keywordsPaginate}
                    page={this.state.keywordsPage}
                    count={this.state.keywordsCount}
                    total={this.state.keywordsTotal}
                  >
                    <div className="notes__toolbar">
                      <input
                        type="radio"
                        name="displayType"
                        id="listByDate"
                        value="listByDate"
                        checked={this.state.displayType === 'listByDate'}
                        onChange={this.handleDisplayTypeChange}
                      />
                      <label htmlFor="listByDate">Nach Datum</label>

                      <input
                        type="radio"
                        name="displayType"
                        id="listByAlphabet"
                        value="listByAlphabet"
                        checked={this.state.displayType === 'listByAlphabet'}
                        onChange={this.handleDisplayTypeChange}
                      />
                      <label htmlFor="listByAlphabet">Alphabetisch</label>
                    </div>

                    <KeywordList
                      keywords={this.state.keywords}
                      linkParams={{
                        keywordsPage: this.state.keywordsPage,
                        fotonotesPage: this.state.fotonotesPage,
                      }}
                    />
                  </Pagination>
                </div>
              )}
          </LoaderIndicator>
        </section>

        <section className="notes__section dark">
          <h2 className="notes__list-title">Neueste Fotonotizen</h2>

          <LoaderIndicator loaded={Array.isArray(this.state.fotonotes)}>
            {Array.isArray(this.state.fotonotes) &&
              this.state.fotonotes.length > 0 && (
                <div>
                  <Pagination
                    paginate={this.fotonotesPaginate}
                    page={this.state.fotonotesPage}
                    count={this.state.fotonotesCount}
                    total={this.state.fotonotesTotal}
                  >
                    <FotonotesList
                      fotonotes={this.state.fotonotes}
                      linkParams={{
                        keywordsPage: this.state.keywordsPage,
                        fotonotesPage: this.state.fotonotesPage,
                      }}
                    />
                  </Pagination>
                </div>
              )}
          </LoaderIndicator>
        </section>

        {/*
        {this.state.keywordsWithinLastYear.length > 0 ? (
          <section className="notes__section">
            <h2 className="notes__list-title">
              Innovationsstichworte: Letzte 12 Monate
            </h2>

            <KeywordList
              keywords={this.state.keywordsWithinLastYear}
              displayType={this.state.displayType}
            />

            <Pagination
              paginate={this.keywordsYearPaginate}
              page={this.state.keywordsYearPage}
              count={this.state.keywordsYearCount}
              total={this.state.keywordsYearTotal}
            />
          </section>
        ) : (<LoaderIndicator />)}
*/}
      </Fragment>
    )
  }
}

export default Notes
