const ImageUrlGenerator = (image, slug) => {
  if (slug) {
    return (
      slug.replace(/[\s/]+$/, '') +
      '/' +
      image.substr(0, 1) +
      '/' +
      image.substr(0, 2) +
      '/' +
      image.substr(0, 3) +
      '/' +
      image
    )
  }

  return image
}

export default ImageUrlGenerator
