import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import { RessourceSingle } from '../'

class OrganisationSingle extends Component {
  static contextType = HttpClientContext

  fields = [
    {
      title: 'Aktiv',
      ident: 'active',
      type: 'checkbox',
    },
    {
      title: 'Name',
      ident: 'name',
      type: 'string',
    },
    {
      title: 'Homepage',
      ident: 'homepage',
      type: 'string',
    },
    {
      title: 'Logo',
      ident: 'logo',
      type: 'string',
    },
    {
      title: 'Beschreibung',
      ident: 'profileText',
      type: 'text',
    },
  ]

  render() {
    return (
      <RessourceSingle
        endpoint="organisation"
        fields={this.fields}
        redirectAfterRequest={this.context.isAppAdmin()}
      />
    )
  }
}

export default OrganisationSingle
