import React from 'react'
import LoaderIndicator from '../LoaderIndicator'
import Entry from './Entry'

export default props => {
  const { ideas, linkParams } = props

  return ideas.length > 0 ? (
    <ul>
      {ideas.map((idea, index) => (
        <Entry key={idea.id} idea={idea} linkParams={linkParams} />
      ))}
    </ul>
  ) : (
    <LoaderIndicator />
  )
}
