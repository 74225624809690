import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { ReactComponent as MinusIcon } from '../../styles/images/minus.svg'
import { ReactComponent as PencilIcon } from '../../styles/images/pencil.svg'
import { Pagination } from '../'

class RessourceList extends Component {
  constructor(props) {
    super(props)

    const actionColumns = this.buildActionColumns()
    this.state = {
      data: [],
      actionColumns,
      page: 0,
      count: 12,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const httpClient = this.context

    const { page, count } = this.state

    let url = `/admin/${this.props.endpoint}/`
    url += `?page=${page}`
    url += `&count=${count}`

    const data = await httpClient.get(url)

    this.setState({ data: data.entities, total: data.pagination.count })
  }

  paginate = page => {
    this.setState({ page }, this.fetchData)
  }

  buildActionColumns = () => {
    return [
      {
        title: '',
        ident: 'edit',
        render: id => (
          <button
            className="admin-area__button-edit"
            onClick={() => this.handleEdit(id)}
          >
            <PencilIcon />
          </button>
        ),
      },
      {
        title: '',
        ident: 'delete',
        render: id => (
          <button
            className="admin-area__button-delete"
            onClick={() => this.handleDelete(id)}
          >
            <MinusIcon />
          </button>
        ),
      },
    ]
  }

  handleEdit = id => {
    const { endpoint, history } = this.props

    history.push(`/admin/${endpoint}/${id}/edit`)
  }

  handleDelete = async id => {
    const httpClient = this.context
    const { endpoint } = this.props

    const updatedData = await httpClient.delete(`/${endpoint}/${id}`)
    this.setState({ data: updatedData })
  }

  render() {
    const { actionColumns, data } = this.state
    const { columns, endpoint } = this.props

    return (
      <Fragment>
        <Pagination
          paginate={this.paginate}
          page={this.state.page}
          count={this.state.count}
          total={this.state.total}
        >
          <table>
            <thead>
              <tr>
                {columns.map(col => (
                  <th key={col.ident}>{col.title}</th>
                ))}

                {actionColumns.map(col => (
                  <th key={col.ident} />
                ))}
              </tr>
            </thead>

            <tbody>
              {data.map(org => (
                <tr key={org.id}>
                  {columns.map(col => (
                    <td key={col.ident}>
                      {col.render ? col.render(org[col.ident]) : org[col.ident]}
                    </td>
                  ))}

                  {actionColumns.map(col => (
                    <td key={col.ident}>{col.render(org.id)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Pagination>

        <div className="admin-area__button-group">
          <Link
            to={`/admin/${endpoint}/new`}
            className="admin-area__button-add"
          >
            <PlusIcon />
          </Link>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(RessourceList)

RessourceList.contextType = HttpClientContext
