import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '../../styles/images/home.svg'
import { ReactComponent as Chevron } from '../../styles/images/chevron.svg'

class Navigation extends Component {
  render() {
    var hasSubnav = !this.props.location.pathname.indexOf('/innovationroom')
    return (
      <nav className={hasSubnav ? 'navigation--submenu-open' : ''}>
        <ul className="navigation__list navigation__list--user">
          <li className="navigation__list-item">
            <NavLink
              to="/start"
              className="navigation__link navigation__link--home"
              activeClassName="navigation__link--active"
            >
              <HomeIcon />
            </NavLink>
          </li>
          <li className="spacer">
            <Chevron />
          </li>
          <li className="navigation__list-item">
            <NavLink
              to="/notes"
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              Stichworte
            </NavLink>
          </li>
          <li className="spacer">
            <Chevron />
          </li>
          <li className="navigation__list-item">
            <NavLink
              to="/innovationroom"
              className="navigation__link"
              activeClassName="navigation__link--active"
            >
              Innovationsraum
            </NavLink>

            <ul className="subnavigation__list subnavigation__list--user">
              <li className="subnavigation__list-item">
                <NavLink
                  to="/innovationroom/innovation"
                  className="subnavigation__link"
                  activeClassName="subnavigation__link--active"
                >
                  Innovationen
                </NavLink>
              </li>
              <li className="subnavigation__list-item">
                <NavLink
                  to="/innovationroom/optimisation"
                  className="subnavigation__link"
                  activeClassName="subnavigation__link--active"
                >
                  Optimierungen
                </NavLink>
              </li>
              <li className="subnavigation__list-item">
                <NavLink
                  to="/innovationroom/product"
                  className="subnavigation__link"
                  activeClassName="subnavigation__link--active"
                >
                  Produkte
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    )
  }
}

export default withRouter(Navigation)
