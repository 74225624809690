import React from 'react'
import { Link } from 'react-router-dom'
import { IdeaTypes } from '../../utils/Ideas'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'

const SearchEntry = props => {
  const { result } = props

  return (
    <Link
      to={IdeaTypes.detailsRoute(result)}
      className={'search-result__entry'}
    >
      <div className={'search-result__entry__head'}>
        {IdeaTypes.typeIcon(
          result.type,
          'search-result__entry__head__type-icon'
        )}
        <div
          className={'search-result__entry__head__title'}
          dangerouslySetInnerHTML={{
            __html: result.title,
          }}
        />
      </div>
      <div className={'search-result__entry__details'}>
        <div
          className={'search-result__entry__details__description'}
          dangerouslySetInnerHTML={{
            __html: result.description,
          }}
        />
        <ChevronIcon className={'search-result__entry__details-icon'} />
      </div>
    </Link>
  )
}

export default SearchEntry
