import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import { RessourceList } from '../'
import USER_ROLES from '../../utils/UserRoles'

class ProductList extends Component {
  state = {
    columns: [
      {
        title: 'ID',
        ident: 'id',
      },
      {
        title: 'Name',
        ident: 'title',
      },
    ],
  }

  async componentDidMount() {
    const httpClient = this.context

    if (this.context.isAppAdmin()) {
      const organisations = await httpClient.get('/admin/organisation/all')
      const columns = this.state.columns

      columns.push({
        title: 'Organisation',
        ident: 'organisation',
        roles: [USER_ROLES.APP_ADMIN],
        render: id => organisations.find(o => o.id === id).name,
      })

      this.setState({ columns })
    }
  }

  render() {
    return (
      <RessourceList
        endpoint="product"
        columns={this.state.columns}
        count={12}
      />
    )
  }
}

export default ProductList

ProductList.contextType = HttpClientContext
