import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as Signet } from '../../styles/images/dipia-logo-signet.svg'
import { ReactComponent as Claim } from '../../styles/images/dipia-logo-claim.svg'
import { ReactComponent as ButtonIcon } from '../../styles/images/chevron-blue.svg'

class Login extends Component {
  static contextType = HttpClientContext

  state = {
    email: '',
    password: '',
    hasError: false,
    redirectAfterLogin: false,
  }

  componentDidMount() {
    const httpClient = this.context

    if (httpClient.isUserAuthenticated()) {
      this.setState({ redirectAfterLogin: true })
    }
  }

  handleChange = event => {
    const { name, value } = event.target

    this.setState({ [name]: value })
  }

  handleSubmit = async event => {
    event.preventDefault()

    const httpClient = this.context
    try {
      await httpClient.loginUser({
        username: this.state.email,
        password: this.state.password,
      })

      this.setState({ redirectAfterLogin: true })
    } catch (err) {
      this.setState({ hasError: true })
    }
  }

  render() {
    if (this.state.redirectAfterLogin) {
      return <Redirect to="/start" />
    }

    return (
      <Fragment>
        <Signet className="login__signet" />
        <Claim className="login__claim" />

        <form onSubmit={this.handleSubmit} className="login__form">
          <p className="login__intro">Bitte melden Sie sich an</p>
          {this.state.hasError && (
            <p style={{ color: 'red' }}>Credentials invalid</p>
          )}

          <label>
            <input
              type="email"
              name="email"
              required="required"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder={'Nutzername'}
            />
          </label>

          <label>
            <input
              type="password"
              name="password"
              required="required"
              value={this.state.password}
              onChange={this.handleChange}
              placeholder={'Passwort'}
            />
          </label>

          <button type="submit">
            <ButtonIcon className="login__button" />
          </button>
        </form>
      </Fragment>
    )
  }
}

export default Login
