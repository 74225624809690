import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as CloseIcon } from '../../styles/images/close-new.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'
import { ReactComponent as EditIcon } from '../../styles/images/pencil.svg'
import { ReactComponent as RefreshIcon } from '../../styles/images/reload.svg'
import Confirmation from '../Confirmation'
import TextareaAutosize from 'react-autosize-textarea'
import { IdeaTypes } from '../../utils/Ideas'
import { ReactComponent as StarEmptyIcon } from '../../styles/images/star-empty.svg'
import { ReactComponent as StarFullIcon } from '../../styles/images/star-full.svg'

function EditButton(props) {
  const { className, onClick } = props

  return (
    <button
      className={`keyword-overview__edit-button ${className}`}
      onClick={onClick}
    >
      <EditIcon />
    </button>
  )
}

function RefreshButton(props) {
  const { className, onClick } = props

  return (
    <button
      className={`keyword-overview__refresh-button ${className}`}
      onClick={onClick}
    >
      <RefreshIcon />
    </button>
  )
}

function SaveButton(props) {
  const { handleSave } = props

  return (
    <button
      className="keyword-overview__save-button keyword-overview__save-button--blue"
      onClick={handleSave}
    >
      <ChevronIcon />
    </button>
  )
}

const defaultInputMode = {
  descriptionInput: false,
}

const defaultConfirmationMode = {
  sectionRelatedTerms: false,
  sectionWebSearch: false,
  data: {
    text: false,
    confirmAction: false,
  },
}

class KeywordOverview extends Component {
  constructor(props) {
    super(props)

    this.state = { ...this.state, ...props }
  }

  state = {
    idea: null,
    convert: null,
    deleteKeyword: null,
    refreshLinks: null,
    confirmation: { ...defaultConfirmationMode },
    isEditing: { ...defaultInputMode },
  }

  descriptionInput = null

  enterEditMode = (refName, callback = () => {}) => {
    this.setState(
      prevState => {
        return {
          isEditing: {
            ...prevState.isEditing,
            [refName]: true,
          },
        }
      },
      () => {
        callback()
        this[refName].focus()
      }
    )
  }

  leaveEditingMode = () => {
    this.setState({ isEditing: { ...defaultInputMode } })
  }

  handleSave = async () => {
    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.post(
      `/innovation_idea/${idea.id}/edit`,
      JSON.stringify(idea)
    )

    if (updatedIdea.redirected) {
      this.props.update()
    } else {
      this.setState({ idea: updatedIdea })
    }

    this.leaveEditingMode()
  }

  handleIdeaEdit = event => {
    const {
      target: { name, value },
    } = event

    this.setState(prevState => {
      return {
        idea: {
          ...prevState.idea,
          [name]: value,
        },
      }
    })
  }

  refreshLinks = async () => {
    const httpClient = this.context
    const idea = this.state.idea

    const updatedIdea = await httpClient.get(
      `/innovation_idea/${idea.id}/enrich`
    )

    if (updatedIdea.redirected) {
      this.props.update()
    } else {
      this.setState({ idea: updatedIdea })
    }
  }

  enterConfirmationMode = (refName, text, confirmAction) => {
    document.querySelector('body').classList.add('confirmation-open')
    this.setState(prevState => {
      return {
        confirmation: {
          ...prevState.confirmation,
          [refName]: true,
          data: {
            text,
            confirmAction,
          },
        },
      }
    })
  }

  leaveConfirmationMode = () => {
    document.querySelector('body').classList.remove('confirmation-open')
    this.setState({ confirmation: { ...defaultConfirmationMode } })
  }

  toggleFavorite = async () => {
    const httpClient = this.context

    const { idea } = this.state
    const url = `/user/favorite/${idea.id}`

    idea.isFavorite ? await httpClient.delete(url) : await httpClient.post(url)
    idea.isFavorite = !idea.isFavorite

    this.setState({ idea })
  }

  render() {
    const {
      idea,
      deleteKeyword,
      confirmation,
      isEditing,
      location,
    } = this.state
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const inFavorite = location.pathname.includes('/favorites')

    return [
      <h2 key="keyword_headline" className="notes__list-title">
        Innovationsstichworte
      </h2>,
      <div key="keyword_content" className="keyword__container">
        <span className="keyword-overview__header">
          Innovationsstichwort Detailansicht
          <Link
            to={{
              pathname: isCeo
                ? `/ceo${inFavorite ? '/favorites' : '/notes'}`
                : '/notes',
              linkParams: location.linkParams || {},
            }}
            className="keyword-overview__back-link"
          >
            <span className="keyword-overview__icon keyword-overview__icon--back">
              <CloseIcon />
            </span>
          </Link>
        </span>

        {isEditing.descriptionInput && !isCeo ? (
          <span className="keyword-overview__title">
            <TextareaAutosize
              className="keyword-overview__title__input"
              name="title"
              ref={el => (this.titleInput = el)}
              value={idea.title}
              onChange={this.handleIdeaEdit}
            />
            <SaveButton
              className="keyword-overview__edit-button--blue"
              handleSave={this.handleSave}
            />
          </span>
        ) : (
          <span className="keyword-overview__title hyphenate">
            {idea.title}
            {!isCeo ? (
              <EditButton
                className="keyword-overview__edit-button--blue"
                onClick={() => this.enterEditMode('descriptionInput')}
              />
            ) : (
              <button
                type="button"
                className={`ceo-favorite-button${
                  idea.isFavorite ? ' ceo-favorite-button-active' : ''
                }`}
                onClick={() => this.toggleFavorite()}
              >
                {idea.isFavorite ? <StarFullIcon /> : <StarEmptyIcon />}
              </button>
            )}
          </span>
        )}

        <p className="keyword-overview__description hyphenate">
          {isEditing.descriptionInput && !isCeo ? (
            <TextareaAutosize
              className="keyword-overview__description__input"
              name="description"
              innerRef={el => (this.descriptionInput = el)}
              value={idea.description}
              onChange={this.handleIdeaEdit}
            />
          ) : (
            idea.description ||
            'Zu Ihrem Stichwort konnte keine automatische Beschreibung erstellt werden. Bitte geben Sie die Beschreibung manuell ein'
          )}
        </p>

        {idea.wikipediaUrl && (
          <span className="keyword-overview__description-hint">
            <span className="keyword-overview__description-hint__source">
              Automatisch abgerufener Text von:
              <a
                href={idea.wikipediaUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="keyword-overview__description-hint__source-title"
              >
                Wikipedia
              </a>
            </span>
            Der Text ist nicht korrekt oder unpräzise? Sie können ihn jederzeit
            selbst bearbeiten.
          </span>
        )}

        <span className="keyword-overview__caption">
          Automatische Websuche zum Stichwort
          {!isCeo && (
            <RefreshButton
              className="keyword-overview__refresh-button--blue"
              onClick={this.refreshLinks}
            />
          )}
        </span>

        <ul className="link-list">
          {idea.webLinks.map(link => (
            <li className="link-list-item" key={link.id}>
              <button
                type="button"
                onClick={() =>
                  this.enterConfirmationMode(
                    'sectionWebSearch',
                    `SEITE IM BROWSER ÖFFNEN?`,
                    () => {
                      window.open(link.url, '_blank')

                      this.leaveConfirmationMode()
                    }
                  )
                }
              >
                {link.url}
              </button>
            </li>
          ))}
        </ul>

        {confirmation.sectionWebSearch && (
          <Confirmation
            text={confirmation.data.text}
            confirmAction={confirmation.data.confirmAction}
            declineAction={this.leaveConfirmationMode}
          />
        )}

        {!isCeo && [
          <span
            key="convert_headline"
            className="keyword-overview__header-create"
          >
            Erstellen:
          </span>,
          <Link
            key="convert_innovation"
            to={`/notes/keyword/${this.state.idea.id}/convert/to/${
              IdeaTypes.INNOVATION
            }`}
            className="keyword-overview__button keyword-overview__button--confirm"
          >
            <span>Innovationsidee aus Stichwort</span>
            <span className="keyword-overview__icon keyword-overview__icon--confirm">
              <ChevronIcon />
            </span>
          </Link>,
          <Link
            key="convert_optimisation"
            to={`/notes/keyword/${this.state.idea.id}/convert/to/${
              IdeaTypes.OPTIMISATION
            }`}
            className="keyword-overview__button keyword-overview__button--confirm"
          >
            <span>Optimierungsidee aus Stichwort</span>
            <span className="keyword-overview__icon keyword-overview__icon--confirm">
              <ChevronIcon />
            </span>
          </Link>,
          <Link
            key="convert_product"
            to={`/notes/keyword/${this.state.idea.id}/convert/to/${
              IdeaTypes.PRODUCT
            }`}
            className="keyword-overview__button keyword-overview__button--confirm"
          >
            <span>Produktidee aus Stichwort</span>
            <span className="keyword-overview__icon keyword-overview__icon--confirm">
              <ChevronIcon />
            </span>
          </Link>,
          <button
            key="convert_delete"
            type="button"
            className="keyword-overview__button keyword-overview__button--delete"
            onClick={deleteKeyword}
          >
            <span>Stichwort aus DIPIA entfernen</span>
            <span className="keyword-overview__icon keyword-overview__icon--delete">
              <CloseIcon />
            </span>
          </button>,
        ]}
      </div>,
    ]
  }
}

export default withRouter(KeywordOverview)

KeywordOverview.contextType = HttpClientContext
