import React from 'react'
import { ReactComponent as Close } from '../../styles/images/close.svg'

function Confirmation(props) {
  const { text, confirmAction, declineAction, ref, fullsize, inline } = props

  return (
    <div
      className={`confirmation__overlay${
        inline ? ' confirmation__overlay-inline' : ''
      }`}
      ref={ref}
    >
      <div
        className={`confirmation__container${
          fullsize ? ' confirmation__container-fullsize' : ''
        }`}
      >
        <span>{text}</span>
        <div className="confirmation__button-group">
          <button
            type="button"
            className="confirmation__button-confirm"
            onClick={confirmAction}
          >
            Ja
          </button>
          <button
            type="button"
            className="confirmation__button-decline"
            onClick={declineAction}
          >
            <Close />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Confirmation
