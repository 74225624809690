import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'

class Entry extends Component {
  static contextType = HttpClientContext

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const { idea, linkParams } = this.props
    const isFavorite = idea.isFavorite
    const user = this.context.getUser()

    return (
      <li
        key={idea.id}
        className={`keyword-list-item${
          user.username === idea.owner ? ' keyword-list-item-own' : ''
        }`}
      >
        <Link
          to={{
            pathname: `${
              isCeo ? `/ceo${isFavorite ? '/favorites' : '/notes'}` : '/notes'
            }/keyword/${idea.id}/confirm`,
            linkParams,
          }}
        >
          {idea.title}
        </Link>
      </li>
    )
  }
}

export default Entry
