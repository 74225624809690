import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'

class InnovationRoom extends Component {
  static contextType = HttpClientContext

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()

    return <Redirect to={`${isCeo ? '/ceo' : ''}/innovationroom/innovation`} />
  }
}

export default InnovationRoom
