import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'
import { Redirect } from 'react-router-dom'
import FotonoteOverview from './FotonoteOverview'
import { InnovationCreate } from '..'

class FotonoteConfirmation extends Component {
  static contextType = HttpClientContext

  state = {
    idea: null,
    isInnovationInputMode: false,
    isOptimisationInputMode: false,
    isProductInputMode: false,
    ideaWasDeleted: false,
    redirect: false,
    products: [],
    route: '',
  }

  async componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props
    const httpClient = this.context
    const idea = await httpClient.get(`/fotonote_idea/${id}`)
    let products = await httpClient.get('/product/')
    if (idea.pictures.length > 0) {
      idea.preview = `/uploads/images/${idea.pictures[0].file}`
    }

    // Add default value to reset to
    products.unshift({ id: '', title: 'Produkt' })

    this.setState({ idea, products })
  }

  toggleView = type => {
    // resets view to default when no type is provided
    this.setState({
      // FIXME: Only allow Innovation-Ideas for now
      isInnovationInputMode: type == 'innovation',
      // isOptimisationInputMode: type == 'optimisation',
      // isProductInputMode: type == 'product',
    })

    // FIXME: Only allow Innovation-Ideas for now
    if (type == 'innovation') {
      // reset scrollposition
      window.scrollTo({ top: 0 })
    }
  }

  handleFotonoteDeletion = async () => {
    const httpClient = this.context
    await httpClient.delete(`/fotonote_idea/${this.state.idea.id}`)
    this.setState(prevState => {
      return { ideaWasDeleted: true }
    })
  }

  handleInnovationIdeaSubmit = async () => {
    let idea = this.state.idea
    const formData = new FormData()
    formData.append('json', JSON.stringify(idea))
    formData.append('file', idea.image)
    idea.type = 1
    const httpClient = this.context
    const url = `/innovation_idea/${idea.id}/edit`
    await httpClient.post(url, formData)
    this.setState(prevState => {
      return { redirect: 'innovation' }
    })
  }

  handleInputChange = event => {
    const {
      target: { name, value },
    } = event

    const { idea } = this.state
    idea[name] = value
    this.setState({ idea })
  }

  handleImageChange = event => {
    let image = event.target.files[0]
    if (!image) return

    this.setState(prevState => {
      const idea = prevState['idea']
      return {
        idea: {
          ...idea,
          image,
        },
      }
    })
    this.previewImage(image)
  }

  previewImage = image => {
    const reader = new FileReader()

    reader.onload = event =>
      this.setState(prevState => {
        const idea = prevState.idea

        return {
          idea: {
            ...idea,
            preview: event.target.result,
          },
        }
      })

    reader.readAsDataURL(image)
  }

  handleRemoveImage = () => {
    this.setState(prevState => {
      const idea = prevState.idea

      return {
        idea: {
          ...idea,
          image: null,
          preview: '',
        },
      }
    })
  }

  render() {
    const {
      idea,
      isInnovationInputMode,
      isOptimisationInputMode,
      isProductInputMode,
      ideaWasDeleted,
      redirect,
      route,
    } = this.state

    if (!idea) return null
    if (ideaWasDeleted) return <Redirect to={'/notes'} />
    if (redirect)
      //TODO has to differentiate between different target idea types
      return (
        <Redirect
          to={`/innovationroom/${route}/detail/${this.state.idea.id}`}
        />
      )

    if (isInnovationInputMode)
      return (
        <InnovationCreate
          origin={'Fotonotiz'}
          idea={idea}
          toggleView={this.toggleView}
          handleInputChange={this.handleInputChange}
          handleImageChange={this.handleImageChange}
          handleRemoveImage={this.handleRemoveImage}
          submit={this.handleInnovationIdeaSubmit}
          products={this.state.products}
        />
      )

    // FIXME Change once the related interface was implemented
    if (isOptimisationInputMode) return null

    // FIXME Change once the related interface was implemented
    if (isProductInputMode) return null

    return (
      <FotonoteOverview
        idea={idea}
        deleteFotonote={this.handleFotonoteDeletion}
        update={() => this.componentDidMount()}
      />
    )
  }
}

export default FotonoteConfirmation
