import React, { Component, Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
//import { Footer } from '..'

class PublicRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Route
        {...rest}
        render={props => (
          <Fragment>
            <main>
              <Component {...props} />
            </main>

            {/*<Footer />*/}
          </Fragment>
        )}
      />
    )
  }
}

export default withRouter(PublicRoute)

// see: https://stackoverflow.com/a/53241270/2000741
PublicRoute.contextType = HttpClientContext
