import {
  Login,
  StartScreen,
  Notes,
  KeywordConfirmation,
  FotonoteConfirmation,
  CeoEntry,
  CeoFavorites,
  CeoTodo,
  OrganisationList,
  OrganisationSingle,
  DepartmentList,
  DepartmentSingle,
  ProductList,
  ProductSingle,
  CategoryList,
  CategorySingle,
  UserList,
  UserSingle,
  InnovationIdeaDetail,
  OptimisationIdeaDetail,
  ProductIdeaDetail,
  InnovationRoom,
  InnovationRoomInnovation,
  InnovationRoomOptimisation,
  InnovationRoomProduct,
  Convert,
  InnovationIdeaCreate,
  OptimisationIdeaCreate,
  ProductIdeaCreate,
  Search,
} from '../components'

const routes = [
  {
    path: '/',
    exact: true,
    protected: false,
    component: Login,
  },
  {
    path: '/start',
    protected: true,
    component: StartScreen,
  },
  {
    path: '/innovationroom',
    protected: true,
    component: InnovationRoom,
    exact: true,
  },
  {
    path: '/innovationroom/innovation',
    protected: true,
    component: InnovationRoomInnovation,
    exact: true,
  },
  {
    path: '/innovationroom/innovation/detail/:id',
    protected: true,
    component: InnovationIdeaDetail,
  },
  {
    path: '/innovationroom/innovation/new',
    protected: true,
    component: InnovationIdeaCreate,
  },
  {
    path: '/innovationroom/optimisation',
    protected: true,
    component: InnovationRoomOptimisation,
    exact: true,
  },
  {
    path: '/innovationroom/optimisation/detail/:id',
    protected: true,
    component: OptimisationIdeaDetail,
  },
  {
    path: '/innovationroom/optimisation/new',
    protected: true,
    component: OptimisationIdeaCreate,
  },
  {
    path: '/innovationroom/product',
    protected: true,
    component: InnovationRoomProduct,
    exact: true,
  },
  {
    path: '/innovationroom/product/detail/:id',
    protected: true,
    component: ProductIdeaDetail,
  },
  {
    path: '/innovationroom/product/new',
    protected: true,
    component: ProductIdeaCreate,
  },
  {
    path: '/notes',
    exact: true,
    protected: true,
    component: Notes,
  },
  {
    path: '/notes/keyword/:id/confirm',
    protected: true,
    component: KeywordConfirmation,
  },
  {
    path: '/notes/fotonote/:id/confirm',
    protected: true,
    component: FotonoteConfirmation,
  },
  {
    path: '/notes/:sourceType/:id/convert/to/:targetType',
    protected: true,
    component: Convert,
  },
  {
    path: '/search/:term',
    protected: true,
    exact: true,
    component: Search,
  },
  {
    path: '/ceo',
    exact: true,
    protected: true,
    component: CeoEntry,
  },
  {
    path: '/ceo/favorites',
    exact: true,
    protected: true,
    component: CeoFavorites,
  },
  {
    path: '/ceo/favorites/keyword/:id/confirm',
    protected: true,
    component: KeywordConfirmation,
  },
  {
    path: '/ceo/favorites/fotonote/:id/confirm',
    protected: true,
    component: FotonoteConfirmation,
  },
  {
    path: '/ceo/favorites/innovation/detail/:id',
    protected: true,
    component: InnovationIdeaDetail,
  },
  {
    path: '/ceo/favorites/optimisation/detail/:id',
    protected: true,
    component: OptimisationIdeaDetail,
  },
  {
    path: '/ceo/favorites/product/detail/:id',
    protected: true,
    component: ProductIdeaDetail,
  },
  {
    path: '/ceo/notes',
    exact: true,
    protected: true,
    component: Notes,
  },
  {
    path: '/ceo/notes/keyword/:id/confirm',
    protected: true,
    component: KeywordConfirmation,
  },
  {
    path: '/ceo/notes/fotonote/:id/confirm',
    protected: true,
    component: FotonoteConfirmation,
  },
  {
    path: '/ceo/innovationroom',
    exact: true,
    protected: true,
    component: InnovationRoom,
  },
  {
    path: '/ceo/innovationroom/innovation',
    exact: true,
    protected: true,
    component: InnovationRoomInnovation,
  },
  {
    path: '/ceo/innovationroom/innovation/detail/:id',
    protected: true,
    component: InnovationIdeaDetail,
  },
  {
    path: '/ceo/innovationroom/optimisation',
    exact: true,
    protected: true,
    component: InnovationRoomOptimisation,
  },
  {
    path: '/ceo/innovationroom/optimisation/detail/:id',
    protected: true,
    component: OptimisationIdeaDetail,
  },
  {
    path: '/ceo/innovationroom/product',
    exact: true,
    protected: true,
    component: InnovationRoomProduct,
  },
  {
    path: '/ceo/innovationroom/product/detail/:id',
    protected: true,
    component: ProductIdeaDetail,
  },
  {
    path: '/ceo/todo',
    exact: true,
    protected: true,
    component: CeoTodo,
  },
  {
    path: '/admin/organisation',
    exact: true,
    protected: true,
    component: OrganisationList,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/organisation/:id/edit',
    protected: true,
    component: OrganisationSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/organisation/new',
    protected: true,
    component: OrganisationSingle,
    roles: ['ROLE_APPLICATION_ADMIN'],
  },
  {
    path: '/admin/department',
    exact: true,
    protected: true,
    component: DepartmentList,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/department/:id/edit',
    protected: true,
    component: DepartmentSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/department/new',
    protected: true,
    component: DepartmentSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/product',
    exact: true,
    protected: true,
    component: ProductList,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/product/:id/edit',
    protected: true,
    component: ProductSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/product/new',
    protected: true,
    component: ProductSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/category',
    exact: true,
    protected: true,
    component: CategoryList,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/category/:id/edit',
    protected: true,
    component: CategorySingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/category/new',
    protected: true,
    component: CategorySingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/user',
    exact: true,
    protected: true,
    component: UserList,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/user/:id/edit',
    protected: true,
    component: UserSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
  {
    path: '/admin/user/new',
    protected: true,
    component: UserSingle,
    roles: ['ROLE_APPLICATION_ADMIN', 'ROLE_ORGANISATION_ADMIN'],
  },
]

export default routes
