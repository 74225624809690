import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import { withRouter, Link, NavLink } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as SearchIcon } from '../../styles/images/search-glass.svg'
import { ReactComponent as Signet } from '../../styles/images/dipia-logo-signet.svg'
import { ReactComponent as Claim } from '../../styles/images/dipia-logo-claim.svg'
import { ReactComponent as CEOIcon } from '../../styles/images/ceo-icon.svg'
import { BurgerMenu } from '..'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'

class Header extends Component {
  state = {
    searchOpen: false,
    searchTerm: '',
  }

  componentDidMount() {
    if (!isEmpty(this.props.match.params)) {
      const {
        match: {
          params: { term },
        },
      } = this.props

      this.setState({ searchTerm: term })
    }
  }

  toggleSearch = (e, searchOpen) => {
    e.preventDefault()
    this.setState({ searchOpen })
  }

  render() {
    const { searchOpen, searchTerm } = this.state
    const { isUserCEO, isCEOEnabled } = this.context
    const labelClass = `header__button header__button--search ${
      searchOpen ? 'header__button--search__open' : ''
    }`

    return (
      <header>
        <BurgerMenu />
        <label className={labelClass}>
          <SearchIcon
            onClick={e => this.toggleSearch(e, !this.state.searchOpen)}
          />
          {searchOpen && (
            <div className="header__button--search__container">
              <input
                onChange={e => this.setState({ searchTerm: e.target.value })}
                value={searchTerm}
              />
              <ChevronIcon
                onClick={() => this.props.history.push(`/search/${searchTerm}`)}
              />
            </div>
          )}
        </label>
        {isUserCEO() && (
          <NavLink
            to={!isCEOEnabled() ? '/ceo' : '/start'}
            className={`header__link ceo${isCEOEnabled() ? ' active' : ''}`}
          >
            <CEOIcon />
          </NavLink>
        )}

        <Link
          to={isCEOEnabled() ? '/ceo/notes' : '/start'}
          className="header__logo"
        >
          <Signet className="header__signet" />
          <Claim className="header__claim" />
        </Link>
      </header>
    )
  }
}

export default withRouter(Header)

Header.contextType = HttpClientContext
