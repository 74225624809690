import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import TextareaAutosize from 'react-autosize-textarea'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as CloseIcon } from '../../styles/images/close-new.svg'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'
import { ReactComponent as EditIcon } from '../../styles/images/pencil.svg'
import { ReactComponent as StarEmptyIcon } from '../../styles/images/star-empty.svg'
import { ReactComponent as StarFullIcon } from '../../styles/images/star-full.svg'
import { IdeaTypes } from '../../utils/Ideas'
import ImageUrlGenerator from '../../utils/ImageUrlGenerator'

function EditButton(props) {
  const { className, onClick } = props

  return (
    <button
      className={`keyword-overview__edit-button ${className}`}
      onClick={onClick}
    >
      <EditIcon />
    </button>
  )
}

function SaveButton(props) {
  const { handleSave } = props

  return (
    <button
      className="keyword-overview__save-button keyword-overview__save-button--blue"
      onClick={handleSave}
    >
      <ChevronIcon />
    </button>
  )
}

const defaultInputMode = {
  descriptionInput: false,
}

class FotonoteOverview extends Component {
  constructor(props) {
    super(props)

    this.state = { ...this.state, ...props }
  }

  state = {
    idea: null,
    convert: null,
    deleteFotonote: null,
    refreshLinks: null,
    isEditing: { ...defaultInputMode },
  }

  descriptionInput = null

  enterEditMode = (refName, callback = () => {}) => {
    this.setState(
      prevState => {
        return {
          isEditing: {
            ...prevState.isEditing,
            [refName]: true,
          },
        }
      },
      () => {
        callback()
        this[refName].focus()
      }
    )
  }

  leaveEditingMode = () => {
    this.setState({ isEditing: { ...defaultInputMode } })
  }

  handleSave = async () => {
    const httpClient = this.context
    const { idea } = this.state

    const updatedIdea = await httpClient.post(
      `/innovation_idea/${idea.id}/edit`,
      JSON.stringify(idea)
    )

    if (updatedIdea.redirected) {
      this.props.update()
    } else {
      this.setState({ idea: updatedIdea })
    }

    this.leaveEditingMode()
  }

  handleIdeaEdit = event => {
    const {
      target: { name, value },
    } = event

    this.setState(prevState => {
      return {
        idea: {
          ...prevState.idea,
          [name]: value,
        },
      }
    })
  }

  toggleFavorite = async () => {
    const httpClient = this.context

    const { idea } = this.state
    const url = `/user/favorite/${idea.id}`

    idea.isFavorite ? await httpClient.delete(url) : await httpClient.post(url)
    idea.isFavorite = !idea.isFavorite

    this.setState({ idea })
  }

  render() {
    const { idea, deleteFotonote, isEditing, location } = this.state
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const inFavorite = location.pathname.includes('/favorites')

    return (
      <div className="keyword__container">
        <span className="keyword-overview__header">
          Fotonotiz Detailansicht
          <Link
            to={{
              pathname: isCeo
                ? `/ceo${inFavorite ? '/favorites' : '/notes'}`
                : '/notes',
              linkParams: location.linkParams || {},
            }}
            className="keyword-overview__back-link"
          >
            <span className="keyword-overview__icon keyword-overview__icon--back">
              <CloseIcon />
            </span>
          </Link>
        </span>

        {idea.pictures.length && (
          <img
            className="keyword-overview__image"
            src={`/uploads/images/${ImageUrlGenerator(
              idea.pictures[0].file,
              idea.organisationSlug
            )}`}
            alt={idea.pictures[0].file}
          />
        )}

        {isEditing.descriptionInput && !isCeo ? (
          <span className="keyword-overview__title">
            <TextareaAutosize
              className="keyword-overview__title__input"
              name="title"
              ref={el => (this.titleInput = el)}
              value={idea.title}
              onChange={this.handleIdeaEdit}
            />
            <SaveButton
              className="keyword-overview__edit-button--blue"
              handleSave={this.handleSave}
            />
          </span>
        ) : (
          <span className="keyword-overview__title hyphenate">
            {idea.title}
            {!isCeo ? (
              <EditButton
                className="keyword-overview__edit-button--blue"
                onClick={() => this.enterEditMode('descriptionInput')}
              />
            ) : (
              <button
                type="button"
                className={`ceo-favorite-button${
                  idea.isFavorite ? ' ceo-favorite-button-active' : ''
                }`}
                onClick={() => this.toggleFavorite()}
              >
                {idea.isFavorite ? <StarFullIcon /> : <StarEmptyIcon />}
              </button>
            )}
          </span>
        )}

        <p className="keyword-overview__description hyphenate">
          {isEditing.descriptionInput && !isCeo ? (
            <TextareaAutosize
              className="keyword-overview__description__input"
              name="description"
              innerRef={el => (this.descriptionInput = el)}
              value={idea.description}
              onChange={this.handleIdeaEdit}
            />
          ) : (
            idea.description
          )}
        </p>

        {!isCeo && [
          <span
            key="convert_headline"
            className="keyword-overview__header-create"
          >
            Erstellen:
          </span>,

          <Link
            key="convert_innovation"
            to={`/notes/fotonote/${idea.id}/convert/to/${IdeaTypes.INNOVATION}`}
            className="keyword-overview__button keyword-overview__button--confirm"
          >
            <span className="keyword-overview__button--label">
              Innovationsidee aus Fotonotiz
            </span>
            <span className="keyword-overview__icon keyword-overview__icon--confirm">
              <ChevronIcon />
            </span>
          </Link>,

          <Link
            key="convert_optimisation"
            to={`/notes/fotonote/${idea.id}/convert/to/${
              IdeaTypes.OPTIMISATION
            }`}
            className="keyword-overview__button keyword-overview__button--confirm"
          >
            <span className="keyword-overview__button--label">
              Optimierungsidee aus Fotonotiz
            </span>
            <span className="keyword-overview__icon keyword-overview__icon--confirm">
              <ChevronIcon />
            </span>
          </Link>,

          <Link
            key="convert_product"
            to={`/notes/fotonote/${idea.id}/convert/to/${IdeaTypes.PRODUCT}`}
            className="keyword-overview__button keyword-overview__button--confirm"
          >
            <span className="keyword-overview__button--label">
              Produktidee aus Fotonotiz
            </span>
            <span className="keyword-overview__icon keyword-overview__icon--confirm">
              <ChevronIcon />
            </span>
          </Link>,

          <button
            key="convert_delete"
            type="button"
            className="keyword-overview__button keyword-overview__button--delete"
            onClick={deleteFotonote}
          >
            <span className="keyword-overview__button--label">
              Stichwort aus DIPIA entfernen
            </span>
            <span className="keyword-overview__icon keyword-overview__icon--delete">
              <CloseIcon />
            </span>
          </button>,
        ]}
      </div>
    )
  }
}

export default withRouter(FotonoteOverview)

FotonoteOverview.contextType = HttpClientContext
