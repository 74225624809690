import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'

class AdminNavigation extends Component {
  static contextType = HttpClientContext

  render() {
    const isAppAdmin = this.context.isAppAdmin()
    const user = this.context.getUser()

    return (
      <Fragment>
        <nav>
          <ul className="navigation__list navigation__list--ceo">
            <li className="navigation__list-item">
              <NavLink
                to="/admin/user"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Nutzer
              </NavLink>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to={`/admin/organisation${
                  !isAppAdmin ? `/${user.organisation.id}/edit` : ''
                }`}
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Organisation
              </NavLink>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to="/admin/department"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Abteilung
              </NavLink>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to="/admin/category"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Kategorie
              </NavLink>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to="/admin/product"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Produkt
              </NavLink>
            </li>
          </ul>
        </nav>
      </Fragment>
    )
  }
}

export default AdminNavigation
