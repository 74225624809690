import React, { Component } from 'react'
import { ReactComponent as IconActive } from '../../styles/images/pagination-active.svg'
import { ReactComponent as IconInactive } from '../../styles/images/pagination-inactive.svg'

class Pagination extends Component {
  paginate = page => {
    const current = window.pageYOffset

    if (current > this.pagination.offsetTop) {
      window.scrollTo({ top: this.pagination.offsetTop })
    }

    this.props.paginate(page)
  }

  render() {
    const { page, count, total, children } = this.props

    if (!total || !children || (Array.isArray(children) && !children.length)) {
      return <div />
    }

    const disabledPrev = !page
    const disabledNext = !total || page * count + count >= total

    return (
      <div ref={el => (this.pagination = el)}>
        {children}

        <ul className="pagination">
          <li>
            <button
              disabled={disabledPrev}
              className="pagination__prev"
              onClick={() => this.paginate(page - 1)}
            >
              {disabledPrev ? <IconInactive /> : <IconActive />}
            </button>
          </li>
          <li>
            {page + 1}/{Math.ceil(total / count)}
          </li>
          <li>
            <button
              disabled={disabledNext}
              className="pagination__next"
              onClick={() => this.paginate(page + 1)}
            >
              {disabledNext ? <IconInactive /> : <IconActive />}
            </button>
          </li>
        </ul>
      </div>
    )
  }
}

export default Pagination
