import React, { Component, Fragment } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { ReactComponent as FavoriteIcon } from '../../styles/images/star-full.svg'
import { ReactComponent as CEOIcon } from '../../styles/images/ceo-icon.svg'

class CeoNavigation extends Component {
  render() {
    var hasSubnav = !this.props.location.pathname.indexOf('/ceo/innovationroom')
    return (
      <Fragment>
        <nav className={hasSubnav ? 'navigation--submenu-open' : ''}>
          <ul className="navigation__list navigation__list--ceo">
            <li className="navigation__list-item">
              <NavLink
                to="/ceo/notes"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Stichworte
              </NavLink>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to="/ceo/innovationroom"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                Innovationsraum
              </NavLink>

              <ul className="subnavigation__list subnavigation__list--user">
                <li className="subnavigation__list-item">
                  <NavLink
                    to="/ceo/innovationroom/innovation"
                    className="subnavigation__link"
                    activeClassName="subnavigation__link--active"
                  >
                    Innovationen
                  </NavLink>
                </li>
                <li className="subnavigation__list-item">
                  <NavLink
                    to="/ceo/innovationroom/optimisation"
                    className="subnavigation__link"
                    activeClassName="subnavigation__link--active"
                  >
                    Optimierungen
                  </NavLink>
                </li>
                <li className="subnavigation__list-item">
                  <NavLink
                    to="/ceo/innovationroom/product"
                    className="subnavigation__link"
                    activeClassName="subnavigation__link--active"
                  >
                    Produkte
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to="/ceo/favorites"
                className="navigation__link navigation__link--favorites"
                activeClassName="navigation__link--active"
              >
                <FavoriteIcon />
              </NavLink>
            </li>
            <li className="navigation__list-item">
              <NavLink
                to="/ceo/todo"
                className="navigation__link"
                activeClassName="navigation__link--active"
              >
                To-Do
              </NavLink>
            </li>
          </ul>
        </nav>

        <p className="navigation__header">
          <CEOIcon />
          Trends & Entwicklungen
        </p>
      </Fragment>
    )
  }
}

export default withRouter(CeoNavigation)
