import React, { Component } from 'react'
import { RessourceSingle } from '../'
import HttpClientContext from '../../utils/HttpClientContext'

class DepartmentSingle extends Component {
  static contextType = HttpClientContext

  state = {
    fields: [
      {
        title: 'Name',
        ident: 'name',
        type: 'string',
      },
    ],
  }

  async componentDidMount() {
    const httpClient = this.context

    if (httpClient.isAppAdmin()) {
      const fields = this.state.fields
      const organisations = await httpClient.get('/admin/organisation/all')

      fields.push({
        title: 'Organisation',
        ident: 'organisation',
        type: 'dropdown',
        choices: organisations.map(org => ({ title: org.name, value: org.id })),
      })

      this.setState({ fields })
    }
  }

  render() {
    return (
      <RessourceSingle
        endpoint="department"
        fields={this.state.fields}
        redirectAfterRequest
      />
    )
  }
}

export default DepartmentSingle
