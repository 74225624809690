import React, { Component } from 'react'
import HttpClientContext from '../../utils/HttpClientContext'

class CeoToolbar extends Component {
  static contextType = HttpClientContext

  state = {
    departments: [],
  }

  async componentDidMount() {
    const httpClient = this.context
    const departments = await httpClient.get('/department/')

    departments.unshift({ id: '', name: 'Abteilung' })

    this.setState({ departments })
  }

  render() {
    const { departments } = this.state
    let { filterByDepartment, currentDepFilter } = this.props

    return (
      <div className="ceo-toolbar">
        <button
          type="button"
          className={`ceo-toolbar__button${
            !currentDepFilter ? ' ceo-toolbar__button--active' : ''
          }`}
          onClick={() => filterByDepartment('')}
        >
          Unternehmensweit
        </button>

        <select
          name="department"
          className={`ceo-toolbar__select${
            currentDepFilter ? ' ceo-toolbar__select--active' : ''
          }`}
          value={currentDepFilter}
          // value={this.state['optimisationIdea']['department']}
          onChange={event => filterByDepartment(event.target.value)}
        >
          <option value="" disabled hidden>
            Nach Abteilung
          </option>
          {departments.map(dep => (
            <option key={dep.id} value={dep.id}>
              {dep.name}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

export default CeoToolbar
