import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import { ReactComponent as InnovationIcon } from '../../styles/images/innovation-idea.svg'
import { ReactComponent as PlusIcon } from '../../styles/images/plus.svg'
import { Pagination, CeoToolbar, IdeaList } from '..'

class InnovationRoom extends Component {
  static contextType = HttpClientContext

  state = {
    latestInnovationIdeas: [],
    page: 0,
    count: 8,
    departmentFilter: '',
  }

  constructor(props) {
    super(props)

    const { location } = props

    if (location.linkParams) {
      const { page } = location.linkParams
      this.state = { ...this.state, ...{ page } }
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const httpClient = this.context

    const { page, count, departmentFilter } = this.state
    let fetchUrl = '/innovation_idea/latest'
    fetchUrl += `?page=${page}`
    fetchUrl += `&count=${count}`

    if (this.context.isUserCEO() && departmentFilter) {
      fetchUrl += `&department=${departmentFilter}`
    }

    const latestInnovationIdeas = await httpClient.get(fetchUrl)

    this.setState({
      latestInnovationIdeas: latestInnovationIdeas.entities,
      total: latestInnovationIdeas.pagination.count,
    })
  }

  paginate = page => {
    this.setState({ page }, this.fetchData)
  }

  filterByDepartment = departmentFilter => {
    this.setState({ departmentFilter }, this.fetchData)
  }

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()

    return (
      <Fragment>
        <section className="innovationRoom__section">
          <h2 className="innovationRoom__title">Innovationsideen</h2>
          <div className="innovationRoom__new-idea">
            <InnovationIcon />
            {!isCeo && (
              <Link
                to="/innovationroom/innovation/new"
                className="innovationRoom__new-idea-button"
              >
                <span>Neue Innovationsidee</span>
                <span className="innovationRoom__new-idea-icon">
                  <PlusIcon />
                </span>
              </Link>
            )}
          </div>
          {isCeo && (
            <CeoToolbar
              filterByDepartment={this.filterByDepartment}
              currentDepFilter={this.state.departmentFilter}
            />
          )}

          {this.state.latestInnovationIdeas && (
            <Pagination
              paginate={this.paginate}
              page={this.state.page}
              count={this.state.count}
              total={this.state.total}
            >
              {/*<button type="button" className="innovationRoom__sorting">
                Nach Datum
              </button>*/}
              <IdeaList
                key="innovation"
                ideas={this.state.latestInnovationIdeas}
                linkParams={{
                  page: this.state.page,
                }}
              />
            </Pagination>
          )}
        </section>
      </Fragment>
    )
  }
}

export default InnovationRoom
