import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { IdeaEditForm } from '../Forms'
import HttpClientContext from '../../utils/HttpClientContext'

class OptimisationIdeaCreate extends Component {
  save = async idea => {
    if (idea.title === '') {
      return
    }

    const httpClient = this.context

    const formData = new FormData()
    formData.append(
      'json',
      JSON.stringify({
        title: idea.title,
        description: idea.description,
        keywords: idea.keywords,
        categories: idea.categories,
        department: idea.department,
        product: idea.product,
        location: idea.location,
      })
    )
    idea.newPictures.map(pic => formData.append('newPictures[]', pic))

    const id = await httpClient.post('/optimisation_idea/new', formData)

    this.props.history.push(`/innovationroom/optimisation/detail/${id}`)
  }

  render() {
    return (
      <IdeaEditForm
        save={(isNew, idea) => this.save(idea)}
        idea={{}}
        backLink={`/innovationroom/optimisation`}
        isNew
        newType={2}
      />
    )
  }
}

export default withRouter(OptimisationIdeaCreate)

OptimisationIdeaCreate.contextType = HttpClientContext
