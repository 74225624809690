import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HttpClientContext from '../../utils/HttpClientContext'
import ImageUrlGenerator from '../../utils/ImageUrlGenerator'

class Entry extends Component {
  static contextType = HttpClientContext

  render() {
    const isCeo = this.context.isUserCEO() && this.context.isCEOEnabled()
    const { idea, linkParams } = this.props
    const isFavorite = idea.isFavorite

    return (
      <li key={idea.id} className="foto-list-item">
        <Link
          to={{
            pathname: `${
              isCeo ? `/ceo${isFavorite ? '/favorites' : '/notes'}` : '/notes'
            }/fotonote/${idea.id}/confirm`,
            linkParams,
          }}
          className="foto-list-fotonote"
          style={
            idea.pictures.length > 0
              ? {
                  backgroundImage: `url(/uploads/images/${ImageUrlGenerator(
                    idea.pictures[0].file,
                    idea.organisationSlug
                  )})`,
                }
              : {}
          }
        >
          Fotonotiz ID {idea.id}
        </Link>
      </li>
    )
  }
}

export default Entry
