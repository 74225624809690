import React from 'react'
import { ReactComponent as ChevronIcon } from '../../styles/images/chevron-blue.svg'
import { ReactComponent as ChevronIconPink } from '../../styles/images/chevron-pink.svg'

function IdeaInput(props) {
  const {
    title,
    icon: Icon,
    isExpanded,
    toggleArea,
    createIdea,
    children,
    className,
  } = props

  return (
    <div
      className={`start__idea${isExpanded ? ' start__idea--expanded' : ''}${
        className ? ' ' + className : ''
      }`}
    >
      <p className="start__idea-title" onClick={toggleArea}>
        <Icon className="start__idea-icon" />
        <span>{title}</span>
        <span className="start__idea-chevron">
          <ChevronIcon />
        </span>
      </p>

      {isExpanded && (
        <form onSubmit={createIdea}>
          <div className="start__idea-contents">
            {children}
            <button className="start__idea-submit" type="submit">
              <ChevronIconPink />
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default IdeaInput
